import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.t('engagement.internal')), 1),
    _createVNode(_component_custom_input, {
      onChangedTextArea: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changedTextArea($event))),
      textAreaWidth: "w-100 w-100",
      type: _ctx.InputTypes.TEXT_AREA,
      name: 'notes'
    }, null, 8, ["type"])
  ]))
}