import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.isDisplayingNotification)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "fixed xs:mx-3 xs:top-3 md:bottom-10 md:right-10 z-50 rounded-lg text-white p-3",
            style: _normalizeStyle('background-color: ' + _ctx.color)
          }, _toDisplayString(_ctx.message), 5))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}