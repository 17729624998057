
import { BaseSideBar, sideBarState, InputTypes, CustomButtonSmall, CustomInput, scrollToFirstError, CustomHeader } from 'vue-3-component-library'
import DateFieldDeadline from '@/components/semantics/DateFieldDeadline.vue'
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import DeadlineCreateCard from '@/components/cards/DeadlineCreateCard.vue'
import { DeadlineRepository } from '@/repositories/DeadlineRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { CustomDeadline } from '@/serializer/CustomDeadline'
import { useForm, ErrorMessage } from 'vee-validate'
import { Deadline } from '@/serializer/Deadline'
import { Visum } from '@/serializer/Visum'
import { useI18n } from 'vue-i18n'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  name: 'CampSideBar',
  components: {
    'custom-button': CustomButtonSmall,
    'custom-header': CustomHeader,
    'base-side-bar': BaseSideBar,
    'custom-input': CustomInput,
    DeadlineCreateCard,
    ErrorMessage,
    DateFieldDeadline,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    existingList: {
      type: Array,
      default: () => {
        return []
      },
    },
    closeOnAdd: {
      type: Boolean,
      default: false,
      required: false,
    },
    sideBarState: {
      type: Object as PropType<sideBarState<Visum>>,
      required: true,
      default: () => {
        'hide'
      },
    },
    isExtraInformationComment: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOverflowHidden: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedGroupId: {
      type: String,
      required: true,
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  emits: ['update:sideBarState', 'actionSuccess'],
  setup(props, context) {
    const selected = computed(() => (props.sideBarState.state === 'list' ? 'BestaandCamp' : 'NieuwCamp'))
    const { resetForm, handleSubmit, validate, values, isSubmitting } = useForm<Visum>()
    const { sideBarState } = toRefs(props)
    const { selectedGroup } = useGroupAndYears()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const closeSideBar = () => {
      context.emit('update:sideBarState', { state: 'hide' })
      resetForm()
    }

    // const onSubmit = async () => {
    //   await validate().then((validation: any) => scrollToFirstError(validation, 'addNewCamp'))
    //   handleSubmit(async (values: Deadline) => {
    //     if (props.sideBarState.state === 'edit') {
    //       await updateDeadline(values)
    //     } else {
    //       await postDeadline(values)
    //     }
    //     closeSideBar()
    //   })()
    // }

    // const updateDeadline = async (data: Deadline) => {
    //   if (data.id) {
    //     await RepositoryFactory.get(DeadlineRepository)
    //       .update(data.id, data)
    //       .then(() => {
    //         context.emit('actionSuccess', 'UPDATE')
    //       })
    //   }
    // }

    // const postDeadline = async (data: Deadline) => {
    //   await RepositoryFactory.get(DeadlineRepository)
    //     .create(data)
    //     .then(() => {
    //       context.emit('actionSuccess', 'POST')
    //     })
    // }

    const items = ref<Array<CustomDeadline>>([{ category: '', label: '' }])

    const addItem = () => {
      items.value.push({ category: '', label: '' })
    }

    const removeItemFromArray = (index: string) => {
      items.value.splice(Number(index), 1)
    }

    return {
      isSubmitting,
      sideBarState,
      closeSideBar,
      selected,
      // onSubmit,
      InputTypes,
      values,
      t,
      addItem,
      items,
      removeItemFromArray,
    }
  },
})
