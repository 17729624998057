
import { ParticipantCheckRepository } from '@/repositories/ParticipantCheckRepository'
import { BaseSideBar, sideBarState, InputTypes, Loader } from 'vue-3-component-library'
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import MemberSidebarItem from '../semantics/MemberSidebarItem.vue'
import { useSelectionHelper } from '../../helpers/selectionHelper'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { CustomButtonSmall } from 'vue-3-component-library'
import SearchInput from '../inputs/SearchInput.vue'
import { Member } from '@/serializer/Member'
import { Check } from '@/serializer/Check'
import { Visum } from '@/serializer/Visum'
import { useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import ParticipantFilter from '../semantics/ParticipantFilter.vue'
import { Filter } from '../../serializer/Filter'
import { usePhoneHelper } from '@/helpers/phoneHelper'
import { BaseMember } from '@/serializer/BaseMember'
import { useNotification } from '@/composable/useNotification'
import useGroupAndYears from '@/composable/useGroupAndYears'
import { ParticipantRepository } from '@/repositories/ParticipantRepository'

export default defineComponent({
  name: 'LocationCreateSideBar',
  components: {
    'base-side-bar': BaseSideBar,
    SearchInput,
    MemberSidebarItem,
    CustomButtonSmall,
    ParticipantFilter,
    Loader
  },
  props: {
    sideBarState: {
      type: Object as PropType<sideBarState<any>>,
      required: true,
      default: () => {
        'hide'
      },
    },
    check: {
      type: Object as PropType<Check>,
      required: true
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  emits: ['update:sideBarState', 'actionSuccess'],
  setup(props, context) {
    const filterChild = ref<any>(null)
    const selected = computed(() => (props.sideBarState.state === 'list' ? 'BestaandCamp' : 'NieuwCamp'))
    const { displayCheck, checkForIdMatch } = useSelectionHelper()
    const fetchedMembers = ref<Member[]>([])
    const { sideBarState } = toRefs(props)
    const isPatching = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const isInit = ref<boolean>(false)
    const { handleSubmit } = useForm()
    const { checkIfIsMobileSize } = usePhoneHelper()
    const { selectedGroup } = useGroupAndYears()
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const filter = ref<Filter>({ gender: '', ageMin: '', ageMax: '', type: props.check.value.participantCheckType })

    const changedFilters = (f: Filter) => {
      filter.value = f
      filter.value.type = props.check.value.participantCheckType
    }

    const closeSideBar = () => {

      context.emit('update:sideBarState', { state: 'hide' })
    }

    const { triggerNotification } = useNotification()

    const onSubmit = async () => {
      let isNoEmail: boolean = false

      fetchedMembers.value.forEach((member: BaseMember) => {
        if (member.isChecked) {
        }
        if ((member.isChecked && !member.email) && (props?.check?.checkParent?.name === 'members_leaders_responsible_main' || props?.check?.checkParent?.name === 'members_leaders_responsible_adjunct')) {
          isNoEmail = true
          triggerNotification(`${t('checks.participant-check.no-email-part-1')} ${member.fullName} ${t('checks.participant-check.no-email-part-2')}`)
        }
      })

      if (isNoEmail === false) {
        handleSubmit(async () => {
          patchMembers(fetchedMembers.value)
        })()
      }
      isNoEmail = false
    }

    const patchMembers = async (data: Member[]) => {
      isPatching.value = true
      await RepositoryFactory.get(ParticipantCheckRepository)
        .update(selectedGroup.value.groupAdminId, props.check.endpoint, data)
        .then((res) => {
          if (res !== false) {
            context.emit('actionSuccess', 'PATCH')
          }
          isPatching.value = false
          closeSideBar()
        })
    }

    const fetchedSearchResults = (results: Member[]) => {
      const finalResult: any = []
      //ALSO CHECK ALREADY ADDED MEMBERS IN SEARCH RESULTS
      results.forEach((member: Member) => {
        if (!props.check.value.participants.some((res: any) => res.id.replaceAll('-', '') === member.id.replaceAll('-', ''))) {
          finalResult.push(member)
        }
      })

      loading.value = false
      //KEEP THE CHECKED MEMBERS
      let checkedMembers: Member[] = []

      fetchedMembers.value.forEach((fetchedMember: Member) => {
        if (fetchedMember.isChecked) {
          checkedMembers.push(fetchedMember)
        }
      })

      //SET CHECKED MEMBERS
      fetchedMembers.value = checkedMembers

      //ADD FETCHED RESULTS ONLY IF IT'S NOT ALREADY CHECKED
      finalResult.forEach((r: Member) => {
        if (!(fetchedMembers.value.some((f: Member) => checkForIdMatch(f, r)))) {
          fetchedMembers.value.push(r)
        }
      })
    }

    const selectAllFetchedMembers = () => {
      fetchedMembers.value.forEach((fetchedMember: any) => {
        fetchedMember.isChecked = true
      })
    }

    const fetchInitMembers = async () => {
      const finalResult: any = []
      loading.value = true
      await RepositoryFactory.get(ParticipantRepository)
        .search(selectedGroup.value.groupAdminId, '').then((results) => {
          results.forEach((member: Member) => {
            if (!props.check.value.participants.some((res: any) => res.id.replaceAll('-', '') === member.id.replaceAll('-', ''))) {
              finalResult.push(member)
            }
          })
          fetchedMembers.value = finalResult
          loading.value = false
        })
    }

    if (props.check.value.participantCheckType === 'M') {
      fetchInitMembers()
    }

    return {
      selectAllFetchedMembers,
      fetchedSearchResults,
      fetchedMembers,
      changedFilters,
      sideBarState,
      closeSideBar,
      displayCheck,
      InputTypes,
      isPatching,
      selected,
      onSubmit,
      loading,
      filter,
      t,
      filterChild,
      checkIfIsMobileSize,
      isInit,
      ParticipantRepository
    }
  },
})
