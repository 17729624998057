
import SectionsSettings from '@/components/settings/SectionsSettings.vue'
import DeadlineSettings from '@/components/settings/DeadlineSettings.vue'
import { useNotification } from '@/composable/useNotification'
import useGroupAndYears from '@/composable/useGroupAndYears'
import Forbidden from '@/components/semantics/Forbidden.vue'
import { useNavigation } from '@/composable/useNavigation'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { SectionsSettings, DeadlineSettings, Forbidden },
  name: 'SettingsOverview',
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const { isForbidden } = useNotification()

    const { selectedGroup } = useGroupAndYears()

    const { setBreadcrumbs } = useNavigation()
    const tabState = ref<string>('sections')
    const setTabState = (state: string) => {
      tabState.value = state
    }

    setBreadcrumbs([{ title: 'instellingen', name: 'instellingen', uuid: '' }])

    return {
      selectedGroup,
      setTabState,
      isForbidden,
      tabState,
      t,
    }
  },
})
