
import DeadlineImportantAlert from '../semantics/DeadlineImportantAlert.vue'
import IRightArrow from '../icons/IRightArrow.vue'
import IEmptyCheck from '../icons/IEmptyCheck.vue'
import { Deadline } from '@/serializer/Deadline'
import IImportant from '../icons/IImportant.vue'
import { defineComponent, PropType } from 'vue'
import IChecked from '../icons/IChecked.vue'

export default defineComponent({
  name: 'DeadlineInfoCard',
  components: {
    DeadlineImportantAlert,
    IRightArrow,
    IImportant,
    IEmptyCheck,
    IChecked,
  },
  props: {
    isImportant: Boolean,
    deadline: {
      type: Object as PropType<Deadline>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const arrowClick = () => {
      emit('openDeadline', props.deadline)
    }

    return {
      arrowClick,
    }
  },
})
