
import DocumentsCreateSidebar from '../sideBars/DocumentsCreateSidebar.vue'
import { useNotification } from '@/composable/useNotification'
import Message, { ColorState } from '../semantics/message.vue'
import { CustomButtonSmall } from 'vue-3-component-library'
import { defineComponent, ref, PropType } from 'vue'
import { Check } from '@/serializer/Check'
import { Visum } from '@/serializer/Visum'
import FileItem from './FileItem.vue'
import { useI18n } from 'vue-i18n'
import IInfo from '../icons/IInfo.vue'

export default defineComponent({
  name: 'OverviewFiles',
  components: {
    'file-item': FileItem,
    'custom-button': CustomButtonSmall,
    DocumentsCreateSidebar,
    Message,
    IInfo
  },
  props: {
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { triggerNotification } = useNotification()
    const createSidebar = ref<any>({ state: 'hide' })
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const openCreateSidebar = (): void => {
      createSidebar.value = { state: 'new' }
    }

    const actionSuccess = (action: string) => {
      if (action === 'PATCH') {
        triggerNotification(t('checks.document-check.notification-patched'))
      }
      if (action === 'DELETE') {
        triggerNotification(t('checks.document-check.notification-deleted'))
      }
      emit('rl', true)
    }

    return {
      openCreateSidebar,
      createSidebar,
      actionSuccess,
      ColorState,
      t
    }
  },
})
