
import { BaseRepository } from '@/repositories/baseRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { Filter } from '@/serializer/Filter'
import { defineComponent, PropType, ref, watch } from 'vue'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  name: 'SearchInput',
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Zoek...',
    },
    repository: {
      type: Function as unknown as PropType<new () => BaseRepository>,
      required: false,
      default: Function as unknown as PropType<new () => BaseRepository>,
    },
    filter: {
      type: Object as PropType<Filter>,
      required: false,
      default: () => { return { gender: '', ageMin: '', ageMax: '' } },
    },
    loading: Boolean,
    loadingSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
    start: String,
    end: String,
    disabled: Boolean,
  },
  setup(props, context) {
    let debounce: any
    const query = ref<string>('')
    const options = ref<any>([])
    const { selectedGroup } = useGroupAndYears()

    const search = () => {
      context.emit('update:loading', true)
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        doCall()
      }, 1500)
    }

    const doCall = () => {
      if (query.value !== null || query.value !== undefined) {
        RepositoryFactory.get(props.repository)
          .search(selectedGroup.value.groupAdminId, query.value, props.filter ? props.filter : undefined)
          .then((results: any) => {
            options.value = results
            context.emit('fetchedOptions', options.value)
          })
      }
    }

    watch(() => props.filter.gender, () => {
      search()
    })

    watch(() => props.filter.ageMin, () => {
      search()
    })

    watch(() => props.filter.ageMax, () => {
      search()
    })

    return {
      options,
      search,
      query,
    }
  },
})
