
import { SearchedLocation } from '@/serializer/SearchedLocation'
import IPersonGreen from '@/components/icons/IPersonGreen.vue'
import IPhoneGreen from '@/components/icons/IPhoneGreen.vue'
import IMailGreen from '@/components/icons/IMailGreen.vue'
import { defineComponent, PropType } from 'vue'
import { Check } from '@/serializer/Check'
import { useI18n } from 'vue-i18n'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { LocationCheckRepository } from '@/repositories/LocationCheckRepository'
import { useNotification } from '@/composable/useNotification'
import { usePlaceAutocompleteHelper } from '@/helpers/placeAutocompleteHelper'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  components: { IPersonGreen, IPhoneGreen, IMailGreen, },
  name: 'CustomPopup',
  props: {
    location: {
      type: Object as PropType<SearchedLocation>,
      required: false
    },
    parentLocation: {
      type: Object as PropType<any>,
      required: false
    },
    check: {
      type: Object as PropType<Check>
    },
  },
  setup(props, { emit }) {
    const { selectedGroup } = useGroupAndYears()
    const { getTranslationCountry } = usePlaceAutocompleteHelper()
  
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const edit = () => {
      document.body.classList.add('overflow-hidden')
      emit('edit', props.parentLocation)
    }
    const { triggerNotification } = useNotification()


     const patchLocation = async () => {
      if (props?.check?.endpoint && props?.parentLocation) {

        let newArray: any = []
        props.check.value.locations.forEach((locationVal: any) => {
          if (locationVal.id !== props.parentLocation.id) { 
            newArray.push(locationVal)
          }
        })

        await RepositoryFactory.get(LocationCheckRepository)
        .updateLocationCheckRemove(selectedGroup.value.groupAdminId, props.check.endpoint, newArray)
        .then((p: any) => {
          triggerNotification(t('sidebars.location-sidebar.form.notification-patched'))
          emit('rl', true)
        })
      } 
    }

    const remove = () => {
      patchLocation()
    }
    return {
      getTranslationCountry,
      remove,
      edit,
      t
    }
  }
})
