import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-100 -mt-3"
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "sticky bg-white z-30" }
const _hoisted_4 = {
  key: 0,
  class: "w-100 flex pt-3"
}
const _hoisted_5 = { class: "w-100 md:pt-3 pr-3 pb-3" }
const _hoisted_6 = {
  key: 1,
  class: "h-screen -m-16 grid content-center"
}
const _hoisted_7 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_base_subcategory_card = _resolveComponent("base-subcategory-card")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_information_side_bar = _resolveComponent("information-side-bar")!
  const _component_forbidden = _resolveComponent("forbidden")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.category && _ctx.visum && !_ctx.isForbidden)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_page_header, {
                title: _ctx.category.categoryParent.label,
                subTitle: _ctx.visum.name
              }, null, 8, ["title", "subTitle"])
            ]),
            (_ctx.category && _ctx.visum)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.subCategories, (subCategory) => {
                      return (_openBlock(), _createElementBlock("div", { key: subCategory }, [
                        _createVNode(_component_base_subcategory_card, {
                          onRl: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rl($event))),
                          visum: _ctx.visum,
                          class: "mb-3",
                          subCategory: subCategory,
                          titleTextfield: "Opmerkingen",
                          checks: subCategory.checks,
                          onOpenSidebar: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openSidebar()))
                        }, null, 8, ["visum", "subCategory", "checks"])
                      ]))
                    }), 128)),
                    (_ctx.visum)
                      ? (_openBlock(), _createBlock(_component_page_footer, {
                          key: 0,
                          visum: _ctx.visum
                        }, null, 8, ["visum"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_information_side_bar, {
                    sidebar: _ctx.sidebar,
                    isOverflowHidden: true,
                    onCloseSidebar: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeSidebar())),
                    onOpenSidebar: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openSidebar()))
                  }, null, 8, ["sidebar"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_forbidden),
    (_ctx.isFetchingVisum && !_ctx.isForbidden)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_loader, {
              color: "lightGreen",
              size: "20",
              isLoading: _ctx.isFetchingVisum
            }, null, 8, ["isLoading"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}