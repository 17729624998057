import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "pb-0" }
const _hoisted_2 = { class: "flex gap-1 items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "text-left mt-3 pl-3"
}
const _hoisted_5 = { class: "italic" }
const _hoisted_6 = { class: "pl-3 pb-3" }
const _hoisted_7 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!
  const _component_i_info = _resolveComponent("i-info")!
  const _component_file_item = _resolveComponent("file-item")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_documents_create_sidebar = _resolveComponent("documents-create-sidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_message, {
          class: "p-2",
          title: _ctx.check.checkParent.label,
          color: { state: _ctx.ColorState.GRAY }
        }, null, 8, ["title", "color"]),
        (_ctx.check.checkParent.explanation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_i_info, {
                info: _ctx.check.checkParent.explanation
              }, null, 8, ["info"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.check.value, (file) => {
        return (_openBlock(), _createElementBlock("div", { key: file }, [
          _createVNode(_component_file_item, {
            file: file,
            check: _ctx.check,
            onActionSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionSuccess($event)))
          }, null, 8, ["file", "check"])
        ]))
      }), 128)),
      (_ctx.check.value.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('checks.document-check.no-document')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_custom_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCreateSidebar())),
            class: "mt-4",
            text: _ctx.t('checks.document-check.add-documents')
          }, null, 8, ["text"])
        ])
      ])
    ]),
    _createVNode(_component_documents_create_sidebar, {
      visum: _ctx.visum,
      check: _ctx.check,
      title: _ctx.t('checks.document-check.title'),
      sideBarState: _ctx.createSidebar,
      "onUpdate:sideBarState": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.createSidebar) = $event)),
      onActionSuccess: _cache[3] || (_cache[3] = ($event: any) => (_ctx.actionSuccess($event)))
    }, null, 8, ["visum", "check", "title", "sideBarState"])
  ], 64))
}