import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-3" }
const _hoisted_3 = { class: "p-3" }
const _hoisted_4 = {
  key: 0,
  class: "p-3 bg-white rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_filter_component = _resolveComponent("location-filter-component")!
  const _component_loader = _resolveComponent("loader")!
  const _component_camps_overview_map = _resolveComponent("camps-overview-map")!

  return (_ctx.hasRole('role_administrator'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_location_filter_component, {
            onChangedFilters: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changedFilters($event)))
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_camps_overview_map, { locations: _ctx.campLocations }, {
            loader: _withCtx(() => [
              (_ctx.isFetchingCampLocations)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_loader, {
                      color: "lightGreen",
                      size: "8",
                      isLoading: _ctx.isFetchingCampLocations
                    }, null, 8, ["isLoading"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["locations"])
        ])
      ]))
    : _createCommentVNode("", true)
}