import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cursor-pointer shadow-md rounded-md" }
const _hoisted_2 = { class: "w-full flex items-center gap-3" }
const _hoisted_3 = { class: "text-lg" }
const _hoisted_4 = { class: "text-right" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_chevon_down = _resolveComponent("i-chevon-down")!
  const _component_i_chevon_up = _resolveComponent("i-chevon-up")!
  const _component_passport_menu_item = _resolveComponent("passport-menu-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
      class: "flex items-center p-3 bg-lighterGreen"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title-icon"),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.isMenuOpen)
          ? (_openBlock(), _createBlock(_component_i_chevon_down, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.isMenuOpen)
          ? (_openBlock(), _createBlock(_component_i_chevon_up, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isMenuOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_passport_menu_item, null, {
            data: _withCtx(() => [
              _renderSlot(_ctx.$slots, "data")
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}