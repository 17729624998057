import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "app"
}
const _hoisted_2 = { class: "sticky top-0 pl-4 bg-white z-41 border border-lightGray" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "w-100 md:px-5 md:mx-3 mt-3" }
const _hoisted_5 = { class: "pl-3 w-full h1" }
const _hoisted_6 = { class: "xs:pl-3.5" }
const _hoisted_7 = {
  key: 1,
  class: "flex h-screen -mt-40"
}
const _hoisted_8 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scouts_dummy_bar = _resolveComponent("scouts-dummy-bar")!
  const _component_bread_crumb = _resolveComponent("bread-crumb")!
  const _component_navigation_side_bar = _resolveComponent("navigation-side-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_custom_footer = _resolveComponent("custom-footer")!
  const _component_base_page = _resolveComponent("base-page")!
  const _component_notification = _resolveComponent("notification")!
  const _component_loader = _resolveComponent("loader")!

  return (_ctx.isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_scouts_dummy_bar, null, null, 512), [
          [_vShow, !_ctx.checkIfIsMobileSize()]
        ]),
        _createVNode(_component_base_page, { class: "scouts-bar-padding" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_bread_crumb, {
                  isInternetActive: _ctx.isInternetActive,
                  class: "md:px-5 md:mx-3",
                  home: '/kampvisum-home/',
                  router: _ctx.router,
                  route: _ctx.route
                }, null, 8, ["isInternetActive", "router", "route"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_navigation_side_bar, { class: "xs:mr-4 md:mr-0" }),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$route.meta.titleKey ? _ctx.$t(_ctx.$route.meta.titleKey) : ''), 1),
                  (_openBlock(), _createBlock(_component_router_view, {
                    key: _ctx.$route.path
                  }))
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_custom_footer, { class: "bottom-0 w-100 mt-60" })
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_notification)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_loader, {
            color: "lightGreen",
            size: "20",
            isLoading: true
          })
        ])
      ]))
}