
import { defineComponent } from 'vue'
import { useInfoBarHelper } from '@/helpers/infoBarHelper'

export default defineComponent({
  name: 'IPerson',
  props: {
    info: String
  },
  setup (props: { info: any }) {

    const { setInfo } = useInfoBarHelper()

    const openInfo = () => {
      setInfo(props.info)
      // context.emit('openSidebar')
    }
    return {
      openInfo
    }
  }
})
