import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between p-3" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "mr-2.5" }
const _hoisted_4 = { class: "font-museo text-md font-semibold" }
const _hoisted_5 = { class: "font-museo text-sm font-normal" }
const _hoisted_6 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deadline_important_alert = _resolveComponent("deadline-important-alert")!
  const _component_i_checked = _resolveComponent("i-checked")!
  const _component_i_empty_check = _resolveComponent("i-empty-check")!
  const _component_i_right_Arrow = _resolveComponent("i-right-Arrow")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.arrowClick())),
    class: "cursor-pointer bg-white shadow-md"
  }, [
    (_ctx.deadline.deadlineParent.isImportant)
      ? (_openBlock(), _createBlock(_component_deadline_important_alert, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.deadline.state === 'CHECKED')
            ? (_openBlock(), _createBlock(_component_i_checked, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.deadline.state === 'UNCHECKED')
            ? (_openBlock(), _createBlock(_component_i_empty_check, {
                key: 1,
                gray: true
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.deadline.deadlineParent.label), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.deadline.deadlineParent.dueDate.dateDay) + "/" + _toDisplayString(_ctx.deadline.deadlineParent.dueDate.dateMonth) + "/" + _toDisplayString(_ctx.deadline.deadlineParent.dueDate.dateYear), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_i_right_Arrow)
      ])
    ])
  ]))
}