import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex -ml-8 -mr-8 bg-white border-gray border border-5" }
const _hoisted_3 = { class: "font-semibold font-museo mt-2" }
const _hoisted_4 = { class: "p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sections_settings = _resolveComponent("sections-settings")!
  const _component_deadline_settings = _resolveComponent("deadline-settings")!
  const _component_forbidden = _resolveComponent("forbidden")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isForbidden)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setTabState('sections'))),
              class: _normalizeClass(["text-center w-full cursor-pointer", _ctx.tabState === 'sections' ? 'text-white bg-green' : ''])
            }, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.t('pages.settings.sections.title')), 1)
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.tabState === 'sections')
              ? (_openBlock(), _createBlock(_component_sections_settings, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.tabState === 'deadlines')
              ? (_openBlock(), _createBlock(_component_deadline_settings, { key: 1 }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_forbidden)
  ], 64))
}