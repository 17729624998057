
import DeadlinesSideBar, { Sidebar, SidebarState } from '@/components/sideBars/DeadlinesSidebar.vue'
import CategoryInfoCard from '../components/cards/CategoryInfoCard.vue'
import Engagement from '../components/semantics/Engagement.vue'
import { useSectionsHelper } from '../helpers/sectionsHelper'
import useGroupAndYears from '@/composable/useGroupAndYears'
import Forbidden from '@/components/semantics/Forbidden.vue'
import { useNavigation } from '@/composable/useNavigation'
import { usePhoneHelper } from '@/helpers/phoneHelper'
import { useCampHelper } from '../helpers/campHelper'
import { Loader, CustomButtonSmall } from 'vue-3-component-library'
import { defineComponent, ref } from 'vue'
import { Visum, VisumStates} from '@/serializer/Visum'
import { useNotification } from '@/composable/useNotification'
import { useI18n } from 'vue-i18n'
import useVisum from '@/composable/useVisum'
import CampGlobalStatusLabel from '@/components/semantics/CampGlobalStatusLabel.vue'

export default defineComponent({
  name: 'CampOverview',
  components: {
    'category-info-card': CategoryInfoCard,
    'deadlines-sidebar': DeadlinesSideBar,
    Loader,
    Engagement,
    Forbidden,
    CampGlobalStatusLabel,
    'custom-button': CustomButtonSmall,
  },
  setup() {
    window.scrollTo({ top: 0, behavior: 'auto' })
    const { getSectionsTitle } = useSectionsHelper()
    const { checkIfIsMobileSize } = usePhoneHelper()
    const { getCampByRouteParam } = useCampHelper()
    const { setBreadcrumbs, navigateTowardsPassport } = useNavigation()
    const visum = ref<Visum>()
    const isFetchingVisum = ref<boolean>(true)
    const { selectedGroup } = useGroupAndYears()
    const { isForbidden } = useNotification()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const { getGlobalVisumState } = useVisum()

    getCampByRouteParam().then((v: Visum) => {
      visum.value = v
      isFetchingVisum.value = false
      setBreadcrumbs([{ title: v.name, name: 'kamp', uuid: v.id }])
    })

    const sidebar = ref<Sidebar>({ state: checkIfIsMobileSize() ? SidebarState.CLOSED : SidebarState.OPEN })

    const closeSidebar = () => {
      sidebar.value.state = SidebarState.CLOSED
    }

    const openSidebar = () => {
      sidebar.value.state = SidebarState.OPEN
    }

    const rl = () => {
      visum.value = undefined
      getCampByRouteParam().then((v: Visum) => {
        visum.value = v
        isFetchingVisum.value = false
      })
    }

    return {
      getGlobalVisumState,
      getSectionsTitle,
      isFetchingVisum,
      closeSidebar,
      openSidebar,
      sidebar,
      visum,
      selectedGroup,
      isForbidden,
      rl,
      VisumStates,
      t,
      navigateTowardsPassport
    }
  },
})
