
import DeadlineImportantAlert from '../semantics/DeadlineImportantAlert.vue'
import DeadlineInfoCard from '@/components/cards/DeadlineInfoCard.vue'
import { DeadlineRepository } from '@/repositories/DeadlineRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import DeadlineCard from '@/components/cards/DeadlineCard.vue'
import { Loader } from 'vue-3-component-library'
import { useNotification } from '@/composable/useNotification'
import DeadlineCheck from '../cards/checks/DeadlineCheck.vue'
import { useNavigation } from '@/composable/useNavigation'
import IVerticalDots from '../icons/IVerticalDots.vue'
import { defineComponent, PropType, ref } from 'vue'
import IEmptyCheck from '../icons/IEmptyCheck.vue'
import { Deadline } from '@/serializer/Deadline'
import IImportant from '../icons/IImportant.vue'
import IChecked from '../icons/IChecked.vue'
import { Visum } from '@/serializer/Visum'
import ICross from '../icons/ICross.vue'
import { Flag } from '@/serializer/Flag'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useGroupAndYears from '@/composable/useGroupAndYears'

export enum SidebarState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type Sidebar = {
  state: SidebarState
}

export default defineComponent({
  name: 'DeadlinesSideBar',
  components: {
    'deadline-info-card': DeadlineInfoCard,
    'deadline-card': DeadlineCard,
    DeadlineImportantAlert,
    IVerticalDots,
    IEmptyCheck,
    IImportant,
    IChecked,
    ICross,
    Loader,
    DeadlineCheck,
  },
  props: {
    sidebar: {
      type: Object as PropType<Sidebar>,
      required: true,
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup(props, context) {
    const { navigateTowardsSection } = useNavigation()
    const isFetchingDeadlines = ref<boolean>(true)
    const isDeadlineDetail = ref<boolean>(false)
    const isUpdatingFlag = ref<boolean>(false)
    const selectedDeadline = ref<Deadline>()
    const deadlines = ref<any>([])
    const route = useRoute()
    const { selectedGroup } = useGroupAndYears()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const goBack = () => {
      isDeadlineDetail.value = false
    }

    const closeSideBar = (): void => {
      context.emit('closeSidebar')
    }

    const openSideBar = (): void => {
      context.emit('openSidebar')
    }

    const openDeadline = (event: any) => {
      selectedDeadline.value = event
      isDeadlineDetail.value = true
    }

    const getDeadlines = async () => {
      await RepositoryFactory.get(DeadlineRepository)
        .getArray(props.visum.groupGroupAdminId, props.visum.id)
        .then((d: Array<any>) => {
          deadlines.value = d
          deadlines.value.forEach((dead: Deadline) => {
            if (dead.deadlineParent && dead.deadlineParent.name === 'camp_registration') {
              // openDeadline(dead)
            }
          })
          isFetchingDeadlines.value = false
        })
    }
    const { triggerNotification } = useNotification()
    const toggleFlag = async (flag: Flag, deadline: Deadline) => {
      if (!isUpdatingFlag.value) {
        isUpdatingFlag.value = true
        if (flag.id && deadline.id) {
          await RepositoryFactory.get(DeadlineRepository)
            .updateFlag(selectedGroup.value.groupAdminId, deadline.id, flag.id, { flag: flag.flag })
            .then((deadline: Deadline) => {
              deadlines.value.map((dl: Deadline) => {
                if (dl.id === deadline.id) {
                  dl.state = deadline.state
                }
              })
              triggerNotification(t('sidebars.deadline-sidebar.notification-updated'))
              isUpdatingFlag.value = false
            })
        }
      }
    }

    getDeadlines()

    return {
      navigateTowardsSection,
      isFetchingDeadlines,
      isDeadlineDetail,
      selectedDeadline,
      closeSideBar,
      SidebarState,
      openDeadline,
      openSideBar,
      toggleFlag,
      deadlines,
      goBack,
      route,
      t,
    }
  },
})
