
import { LMap, LTileLayer, LMarker, LIcon, LPopup, LControl } from '@vue-leaflet/vue-leaflet'
import { CustomInput, CustomButtonSmall, Warning } from 'vue-3-component-library'
import { defineComponent, ref, PropType } from 'vue'
import ICenter from '@/components/icons/ICenter.vue'
import CampCustomPopup from './CampCustomPopup.vue'
import { useI18n } from 'vue-i18n'
import { latLng } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import MarkerCluster from "./MarkerCluster.vue";

export default defineComponent({
  components: {
    CampCustomPopup,
    LTileLayer,
    CustomInput,
    LControl,
    MarkerCluster,
    LMarker,
    LPopup,
    LIcon,
    LMap,
    Warning,
    CustomButtonSmall,
    ICenter,
  },
  props: {
    locations: {
      type: Object as PropType<Array<any>>,
      required: false,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 1,

      leafletReady: false,
      leafletObject: null,

      visible: false,
    };
  },
  methods: {
    async onLeafletReady() {
      await this.$nextTick();
    const map = ref<any>(null)
      this.leafletObject = map.leafletObject;
      this.leafletReady = true;
    },
  },
  setup(props, { emit }) {

    const myMap = ref<any>(null)
    const options = ref<any>({scrollWheelZoom: false})
    const center = ref<any>(latLng(50.5039,4.4699))
    const zoom = ref<Number>(5)
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    return {
      t,
      myMap,
      options,
      center,
      zoom,
    }
  },
})
