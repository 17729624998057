
import PassportOverviewItem from '@/components/semantics/PassportOverviewItem.vue'
import PassportNavHeader from '@/components/semantics/PassportNavHeader.vue'
import PassportMenu from '@/components/semantics/PassportMenu.vue'
import IPhoneGreen from '@/components/icons/IPhoneGreen.vue'
import ICalendar from '@/components/icons/ICalendar.vue'
import FileItem from '@/components/upload/FileItem.vue'
import { SubCategory } from '@/serializer/SubCategory'
import { useCampHelper } from '@/helpers/campHelper'
import IMarker from '@/components/icons/IMarker.vue'
import IShield from '@/components/icons/IShield.vue'
import IPuzzle from '@/components/icons/IPuzzle.vue'
import IUsers from '@/components/icons/IUsers.vue'
import ITime from '@/components/icons/ITime.vue'
import IEuro from '@/components/icons/IEuro.vue'
import { defineComponent, ref } from 'vue'
import { Visum } from '@/serializer/Visum'
import { Check } from '@/serializer/Check'
import { useI18n } from 'vue-i18n'
import { Loader } from 'vue-3-component-library'

export default defineComponent({
  components: {PassportOverviewItem, IPhoneGreen, Loader, ITime, PassportNavHeader, PassportMenu, ICalendar, IMarker, IShield, IEuro, IUsers, IPuzzle, FileItem},
  name: 'PassportDocumentsOverview',
  setup() {
    const isFetchingVisum = ref<boolean>(true)
    const { getCampByRouteParam } = useCampHelper()
    const visum = ref<Visum>()

    getCampByRouteParam().then((v: Visum) => {
      visum.value = v
      isFetchingVisum.value = false
    })

    const checkIfFilesAvailable = (subCategories: SubCategory[]) => {
      const areAvailable = ref<boolean>(false)
      subCategories.forEach((sub) => {
        if (sub.checks) {
          sub.checks.forEach((check: Check) => {
            if (check.checkParent?.checkType.checkType === 'FileUploadCheck' && (check.value.length !== 0)) {
              areAvailable.value = true
            }
          })
        }
      })

      return areAvailable.value
    }

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    return {
      t,
      visum,
      isFetchingVisum,
      checkIfFilesAvailable
    }
  },
})
