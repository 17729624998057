import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border border-lightGray mt-3 p-3" }
const _hoisted_2 = { class: "font-semibold font-museo" }
const _hoisted_3 = { class: "flex text-green justify-end items-center gap-3 text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_right_arrow = _resolveComponent("i-right-arrow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.navigate()), ["stop"])),
        class: "hover:underline mt-1 cursor-pointer font-semibold font-museo"
      }, _toDisplayString(_ctx.navText), 1),
      _createVNode(_component_i_right_arrow, {
        class: "cursor-pointer",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.navigate()), ["stop"]))
      })
    ])
  ]))
}