
import PassportOverviewItem from '@/components/semantics/PassportOverviewItem.vue'
import { useSectionsHelper } from '@/helpers/sectionsHelper'
import { useCampHelper } from '@/helpers/campHelper'
import { Visum } from '@/serializer/Visum'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import IPhoneGreen from '@/components/icons/IPhoneGreen.vue'
import ITime from '@/components/icons/ITime.vue'
import { useOfflineData } from '@/composable/useOfflineData'
import { useInternetHelper } from '@/helpers/internetHelper'
import { Category } from '@/serializer/Category'
import { SubCategory } from '@/serializer/SubCategory'
import { Check } from '@/serializer/Check'
import { useDateHelper } from '@/helpers/dateHelper'
import axios from 'axios'
import { CustomButtonSmall } from 'vue-3-component-library'

export default defineComponent({
  components: {
    PassportOverviewItem, IPhoneGreen, ITime, 'custom-button': CustomButtonSmall},
  name: 'PassportOverview',
  setup() {
    const { getSectionsTitle } = useSectionsHelper()
    const { getCampByRouteParam } = useCampHelper()
    const { addVisum, getVisum, updateVisum, addFile } = useOfflineData()
    const { isInternetActive } = useInternetHelper()
    const { dateTimeFromISO, getCurrentDate } = useDateHelper()

    const visum = ref<any>()
    const idbVisum = ref<any>(null)

    const remainingDays = ref<string>('0')

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    window.scrollTo({ top: 0, behavior: 'auto' })

    getCampByRouteParam().then((v: Visum) => {
      visum.value = v
      daysRemaining()
      // getVisum(visum.value.id).then((result) => {
      //   idbVisum.value = result
      // })
    })

    function daysRemaining() {
      var endDate = visum.value.categorySet.categories[0].subCategories[0].checks[0].value.endDate
      if (endDate) {
        var eventdate = dateTimeFromISO(endDate);
        var todaysdate = getCurrentDate();
        remainingDays.value = Math.ceil(eventdate.diff(todaysdate, 'days').values.days).toString();
      } else {
        remainingDays.value = ''
      }
    }

    const navigate = () => {
      window.open(
            "https://www.scoutsengidsenvlaanderen.be/contact", "_blank");
    }

    const setVisumOffline = () => {
      addVisum(JSON.parse(JSON.stringify(visum.value))).then(() => {
        getVisum(visum.value.id).then((result) => {
          idbVisum.value = result
          saveVisumOffline()
        })
      })
    }

    const saveVisumOffline = () => {
      updateVisum(JSON.parse(JSON.stringify(visum.value))).then((res: any) => {
        res.data.categorySet.categories.forEach((category: Category) => {
        if (category.subCategories) {
          category.subCategories.forEach((subCategory: SubCategory) => {
            if (subCategory.checks) {
              subCategory.checks.forEach((check: Check) => {
                if (check.checkParent && check.checkParent.checkType.checkType === 'FileUploadCheck') {
                  check.value.forEach((file: any) => {
                    axios({
                        url: file.url,
                        method: 'GET',
                        responseType: 'blob',
                      }).then((response: any) => {
                      addFile(file.id, { name: file.name, data: response.data})
                    })
                  })
                }
              })
            }
          })
        }
      })

        getVisum(visum.value.id).then((result) => {
          idbVisum.value = result
        })
      })
    }

    const syncVisumOffline = () => {
      saveVisumOffline()
    }

    function replacer(key: any, value: any) {
      // Filtering out properties
      if (key === 'url' || key === 'file') {
        return undefined;
      }
      return value;
    }

    const isObjEq = (val1:any, val2:any) => {
      if (JSON.stringify(val1, replacer) === JSON.stringify(val2, replacer)) {
        return true
      } else {
        return false
      }
    }


    return {
      t,
      visum,
      getSectionsTitle,
      remainingDays,
      setVisumOffline,
      idbVisum,
      isInternetActive,
      syncVisumOffline,
      isObjEq,
      navigate
    }
  },
})
