
import { CustomButtonSmall, Loader, Warning } from 'vue-3-component-library'
import { SectionsRepository } from '@/repositories/SectionsRepository'
import { GroupRepository } from '@/repositories/groupRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { useNotification } from '@/composable/useNotification'
import SectionSidebar from '../sideBars/SectionSideBar.vue'
import { defineComponent, watch, ref } from 'vue'
import SectionItem from '../semantics/SectionItem.vue'
import { Section } from '@/serializer/Section'
import { useI18n } from 'vue-i18n'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  components: {
    SectionSidebar,
    CustomButtonSmall,
    SectionItem,
    Loader,
    warning: Warning,
  },
  name: 'SectionSettings',
  setup() {
    const sectionSideBarState = ref<any>({ state: 'hide', entity: {} })
    const { triggerNotification } = useNotification()
    const { selectedGroup } = useGroupAndYears()
    const groupSections = ref<Section[]>()
    const isWarningDisplayed = ref<Boolean>(false)
    const sectionToBeDeleted = ref<Section>()
    const editSection = (section: Section) => {
      sectionSideBarState.value = {
        state: 'edit',
        entity: section,
      }
    }

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const displayWarning = (section: Section) => {
      isWarningDisplayed.value = true
      sectionToBeDeleted.value = section
    }

    const hideWarning = () => {
      isWarningDisplayed.value = false
    }

    const removeSection = async () => {
      if (sectionToBeDeleted.value && sectionToBeDeleted.value.id) {
        await RepositoryFactory.get(SectionsRepository)
          .removeById(selectedGroup.value.groupAdminId, sectionToBeDeleted.value.id)
          .then(() => {
            getGroupSections()
            isWarningDisplayed.value = false
            triggerNotification(t('pages.settings.sections.notification-deleted'))
          })
      }
    }

    const openSectionSideBar = () => {
      sectionSideBarState.value = { state: 'new' }
    }

    const getGroupSections = async () => {
      await RepositoryFactory.get(GroupRepository)
        .getGroupSections(selectedGroup.value.groupAdminId)
        .then((results: Section[]) => {
          groupSections.value = results
        })
    }

    const actionSuccess = (action: string) => {
      if (action === 'POST') {
        triggerNotification(t('pages.settings.sections.notification-posted'))
        getGroupSections()
      }
      if (action === 'UPDATE') {
        triggerNotification(t('pages.settings.sections.notification-updated'))
        getGroupSections()
      }
      getGroupSections()
    }

    if (selectedGroup.value.groupAdminId) { 
      getGroupSections()
    }
    watch(selectedGroup.value, () => {
      getGroupSections()
    })

    return {
      sectionSideBarState,
      openSectionSideBar,
      isWarningDisplayed,
      sectionToBeDeleted,
      displayWarning,
      selectedGroup,
      groupSections,
      actionSuccess,
      removeSection,
      editSection,
      hideWarning,
      t,
    }
  },
})
