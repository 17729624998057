import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createBlock(_component_message, {
    class: "my-3",
    title: _ctx.t(`global-camp-state.title-${_ctx.state}`),
    text: _ctx.showTextLabel(_ctx.state),
    color: {state: _ctx.colorMap[_ctx.state]},
    hasCheck: false
  }, null, 8, ["title", "text", "color"]))
}