
import { SimpleCheckRepository } from '../../../repositories/SimpleCheckRepository'
import { useNotification } from '../../../composable/useNotification'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { Check, CheckDeserializer } from '@/serializer/Check'
import Tooltip from '@/components/semantics/Tooltip.vue'
import { SimpleCheck } from '@/serializer/SimpleCheck'
import { defineComponent, ref, PropType } from 'vue'
import IInfo from '../../icons/IInfo.vue'
import IEmptyCheck from '@/components/icons/IEmptyCheck.vue'
import { useI18n } from 'vue-i18n'
import { usePhoneHelper } from '@/helpers/phoneHelper'
import useGroupAndYears from '@/composable/useGroupAndYears'

export enum StatusState {
  CHECKED = 'CHECKED',
  EMPTY = 'EMPTY',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export type Status = {
  state: StatusState
}

export default defineComponent({
  name: 'Check',
  components: {
    IInfo,
    Tooltip,
    IEmptyCheck,
  },
  props: {
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
    isSimpleCheck: Boolean,
  },
  setup(props) {
    const { selectedGroup } = useGroupAndYears()
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    
    const simpleCheck = ref<SimpleCheck>(CheckDeserializer(props.check))
    const { triggerNotification } = useNotification()

    const patchCheck = async (simpleCheck: SimpleCheck) => {
      await RepositoryFactory.get(SimpleCheckRepository)
        .update(selectedGroup.value.groupAdminId, simpleCheck.endpoint, simpleCheck)
        .then(() => {
          triggerNotification(t('checks.notification-updated'))
        })
    }

    const toggle = () => {
      if (props.isSimpleCheck) {
        switch (simpleCheck.value.value) {
          case StatusState.EMPTY:
            simpleCheck.value.value = StatusState.CHECKED
            break
          case StatusState.CHECKED:
            simpleCheck.value.value = StatusState.EMPTY
            break
          default:
            simpleCheck.value.value = StatusState.EMPTY
            break
        }
      }
    }

    const { checkIfIsMobileSize } = usePhoneHelper()


    const select = (v: StatusState) => {
      simpleCheck.value.value = v
      patchCheck(simpleCheck.value)
      if (checkIfIsMobileSize()) { 
        // @ts-ignore
        document.getElementById("check-hover").style.display = "none"
      }
    }

    return {
      checkIfIsMobileSize,
      simpleCheck,
      StatusState,
      toggle,
      select,
      t
    }
  },
})
