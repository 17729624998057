import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle(_ctx.existingLocation))),
    class: "p-3 cursor-pointer shadow-md rounded-md"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.existingLocation.name), 1),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.existingLocation.isChecked) = $event)),
          disabled: !_ctx.displayCheck,
          class: _normalizeClass(_ctx.displayCheck ? 'cursor-pointer' : '' ),
          value: true,
          type: "checkbox",
          id: "checked",
          name: "checked"
        }, null, 10, _hoisted_2), [
          [_vModelCheckbox, _ctx.existingLocation.isChecked]
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existingLocation.locations, (location) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "my-3",
        key: location
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(location.name), 1),
        _createTextVNode(" " + _toDisplayString(location.address), 1)
      ]))
    }), 128))
  ]))
}