import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = { class: "flex gap-2 items-center" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "text-left pl-3 mt-3"
}
const _hoisted_6 = {
  key: 0,
  class: "italic"
}
const _hoisted_7 = {
  key: 2,
  class: "text-left pl-3 mt-3"
}
const _hoisted_8 = { class: "italic" }
const _hoisted_9 = {
  key: 3,
  class: "flex pl-3"
}
const _hoisted_10 = { class: "xs:w-100 md:w-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!
  const _component_i_info = _resolveComponent("i-info")!
  const _component_member_item = _resolveComponent("member-item")!
  const _component_custom_button_small = _resolveComponent("custom-button-small")!
  const _component_member_sidebar = _resolveComponent("member-sidebar")!
  const _component_participant_sidebar = _resolveComponent("participant-sidebar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_message, {
        class: "p-2 pr-0",
        title: _ctx.check.checkParent.label,
        color: { state: _ctx.ColorState.GRAY }
      }, null, 8, ["title", "color"]),
      (_ctx.check.checkParent.isMultiple)
        ? (_openBlock(), _createBlock(_component_message, {
            key: 0,
            class: "p-2 pl-0",
            title: `(${_ctx.check.value.count})`,
            color: { state: _ctx.ColorState.GRAY }
          }, null, 8, ["title", "color"]))
        : _createCommentVNode("", true),
      (_ctx.check.checkParent.explanation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_i_info, {
              info: _ctx.check.checkParent.explanation
            }, null, 8, ["info"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.can(_ctx.check.checkParent.requiresPermission))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.check.value.participants, (participant) => {
            return (_openBlock(), _createBlock(_component_member_item, {
              key: participant,
              check: _ctx.check,
              participant: participant,
              onActionSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionSuccess($event))),
              onOpenSidebarToEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openSidebarToEdit($event)))
            }, null, 8, ["check", "participant"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.can(_ctx.check.checkParent.requiresPermission))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.check.value.count === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(!_ctx.check.checkParent.isMultiple ? _ctx.t('checks.participant-check.no-member') : _ctx.t('checks.participant-check.no-members')), 1))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('checks.participant-check.hidden')), 1)
        ])),
    (_ctx.can(_ctx.check.checkParent.requiresPermission))
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_custom_button_small, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openMemberSidebar())),
              class: "w-100 mt-4",
              extraStyle: 'w-100',
              text: _ctx.check.value.participants.length > 0 ? (_ctx.check.checkParent.isMultiple ? _ctx.t('checks.participant-check.add') : _ctx.t('checks.participant-check.change')) : _ctx.t('checks.participant-check.add')
            }, null, 8, ["text"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.check.checkParent.isMember)
      ? (_openBlock(), _createBlock(_component_member_sidebar, {
          key: 4,
          visum: _ctx.visum,
          check: _ctx.check,
          sideBarState: _ctx.sidebar,
          "onUpdate:sideBarState": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sidebar) = $event)),
          onActionSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.actionSuccess($event)))
        }, null, 8, ["visum", "check", "sideBarState"]))
      : _createCommentVNode("", true),
    (!_ctx.check.checkParent.isMember)
      ? (_openBlock(), _createBlock(_component_participant_sidebar, {
          key: 5,
          visum: _ctx.visum,
          check: _ctx.check,
          sideBarState: _ctx.sidebar,
          "onUpdate:sideBarState": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sidebar) = $event)),
          onActionSuccess: _cache[6] || (_cache[6] = ($event: any) => (_ctx.actionSuccess($event)))
        }, null, 8, ["visum", "check", "sideBarState"]))
      : _createCommentVNode("", true)
  ]))
}