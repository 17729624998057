import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "p-2.5 flex items-center gap-3" }
const _hoisted_3 = {
  key: 0,
  class: "p-2.5 items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_marker = _resolveComponent("i-marker")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.location.locations, (subLocation) => {
    return (_openBlock(), _createElementBlock("div", {
      onClick: ($event: any) => (_ctx.openInGoogleMaps(subLocation)),
      class: "flex justify-between border-b-2 hover:text-green cursor-pointer bg-white",
      key: subLocation
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_i_marker),
        _createTextVNode(" (" + _toDisplayString(_ctx.location.name) + ") " + _toDisplayString(subLocation.name ? `${subLocation.name}, ` : '') + _toDisplayString(subLocation.address), 1)
      ]),
      (_ctx.location.startDate && _ctx.location.endDate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.dateFromLocalisedString(_ctx.location.startDate)) + " - " + _toDisplayString(_ctx.dateFromLocalisedString(_ctx.location.endDate)), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1))
  }), 128))
}