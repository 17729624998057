
import IChevonDown from '../icons/IChevonDown.vue'
import { defineComponent, ref } from 'vue'
import { LocationFilter } from '../../serializer/Filter'
import { CustomButtonSmall } from 'vue-3-component-library'
import Cross from '../icons/Cross.vue'
import { useI18n } from 'vue-i18n'
import IChevonUp from '../icons/IChevonUp.vue'
import { usePhoneHelper } from '@/helpers/phoneHelper'



export default defineComponent({
  components: { Cross, IChevonDown, IChevonUp, CustomButtonSmall },
  name: 'LocationFilter',
  props: {
  },
  setup (props, { emit }) {
    const { checkIfIsMobileSize } = usePhoneHelper()
    const d = new Date()
    let year = d.getFullYear();

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const isFilterMenuOpen = ref<boolean>(true)

    const filter = ref<LocationFilter>({ year: year.toString(), startDate: '', endDate: '', groupName: '', groupNumber: '', country: '' })
    
    const filtersChanged = () => {
      emit('changedFilters', filter.value)
    }

    const clearFilters = () => {
      filter.value = { year: '', startDate: '', endDate: '', groupName: '', groupNumber: '', country: '' }
      emit('changedFilters', filter.value)
    }

    const toggleFilterMenu = () => {
      isFilterMenuOpen.value = !isFilterMenuOpen.value
    }

    return {
      checkIfIsMobileSize,
      filtersChanged,
      toggleFilterMenu,
      isFilterMenuOpen,
      clearFilters,
      filter,
      t
    }
  }
})
