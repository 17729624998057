import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "mt-4 font-bold" }
const _hoisted_4 = { class: "flex gap-3" }
const _hoisted_5 = ["id", "value"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "mt-4 font-bold" }
const _hoisted_8 = {
  class: "mt-5 py-4 sticky bottom-0 bg-white pl-3",
  style: {"margin-left":"-20px","margin-right":"-20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_side_bar, {
      isOverflowHidden: true,
      selection: _ctx.selected,
      "is-display": _ctx.sideBarState.state !== 'hide',
      "is-edit": _ctx.sideBarState.state === 'edit',
      name: "Section",
      title: _ctx.title,
      onHideSidebar: _ctx.closeSideBar,
      width: "max-w-xl"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          id: "addNewSection",
          ref: "formDiv",
          class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit', 'd-none': _ctx.sideBarState.state === 'list' }, "flex-col relative overflow-y-scroll h-full px-4 pt-3"]),
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "name.name",
                label: _ctx.t('pages.settings.sections.sidebar.form.section-name')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.t('pages.settings.sections.sidebar.form.gender')), 1),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: option,
                  class: "flex items-center gap-1 mr-0.5"
                }, [
                  _withDirectives(_createElementVNode("input", {
                    class: "cursor-pointer",
                    type: "radio",
                    id: option.value,
                    value: option.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosenGender) = $event))
                  }, null, 8, _hoisted_5), [
                    [_vModelRadio, _ctx.chosenGender]
                  ]),
                  _createElementVNode("label", {
                    class: "cursor-pointer mb-0",
                    for: option.value
                  }, _toDisplayString(option.label), 9, _hoisted_6)
                ]))
              }), 128))
            ]),
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.t('pages.settings.sections.sidebar.form.age-group')), 1),
            (_ctx.optionsAgeGroup)
              ? (_openBlock(), _createBlock(_component_multi_select, {
                  key: 0,
                  class: "mt-2",
                  id: "name.ageGroup",
                  object: true,
                  "track-by": "label",
                  "value-prop": "value",
                  options: _ctx.optionsAgeGroup,
                  value: _ctx.optionsAgeGroup[0],
                  canClear: false,
                  canDeselect: false,
                  onAddSelection: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedValue($event)))
                }, null, 8, ["options", "value"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_custom_button, {
              isSubmitting: _ctx.isSubmitting,
              text: _ctx.sideBarState.state === 'edit' ? _ctx.t('pages.settings.sections.sidebar.edit') : _ctx.t('sidebars.kampvisum-sidebar.buttons.add')
            }, null, 8, ["isSubmitting", "text"])
          ])
        ], 34)
      ]),
      _: 1
    }, 8, ["selection", "is-display", "is-edit", "title", "onHideSidebar"])
  ]))
}