import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-red p-2 flex items-center gap-3"
}
const _hoisted_2 = { class: "text-md font-light m-0 text-white font-aglet" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_important = _resolveComponent("i-important")!

  return true
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_i_important),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('pages.settings.deadlines.important')), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}