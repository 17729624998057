
import {
  LTileLayer,
  LMarker,
  LControl,
  LPopup,
  LIcon,
  LMap,
} from "@vue-leaflet/vue-leaflet";
import { CustomInput, CustomButtonSmall, InputTypes, Warning } from 'vue-3-component-library'
import { SearchedLocation } from '../../../serializer/SearchedLocation'
import { defineComponent, ref, PropType, toRefs } from 'vue'
import ICenter from "@/components/icons/ICenter.vue";
import { latLngBounds, latLng } from 'leaflet'
import CustomPopup from './CustomPopup.vue'
import { Check } from "@/serializer/Check";
import "leaflet/dist/leaflet.css";

export default defineComponent ({
  components: {
    CustomPopup,
    CustomInput,
    CustomButtonSmall,
    LTileLayer,
    LMarker,
    Warning,
    LControl,
    LPopup,
    LIcon,
    LMap,
    ICenter,
  },
  props: {
    center: Object as PropType<Array<number>>,
    locations: {
      type: Object as PropType<Array<SearchedLocation>>,
      required: false,
      default: () => { return [] }
    },
    isCreating: Boolean,
    searchedLocation: {
      type: Object as PropType<SearchedLocation>,
      default: () => { return {} }
    },
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
    searchedLocations: {
      type: Object as PropType<Array<SearchedLocation>>,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const myMap2 = ref<any>(null)
    // THIS APPLICATION USES VUE3-LEAFLET BUT DOCUMENTATION IS ALMOST THE SAME AS VUE2-LEAFLET
    // https://vue2-leaflet.netlify.app/quickstart/
    const isWarningDisplayed = ref<Boolean>(false)

    const centerClickedLocation = (lat: any,lng: any) => {
      let map = myMap2.value.leafletObject
      map.flyTo(latLng(lat, lng), 18)
    }

    const displayWarning = () => {
      isWarningDisplayed.value = true
    }

    const hideWarning = () => {
      isWarningDisplayed.value = false
    }
    const { searchedLocation, searchedLocations } = toRefs(props)

    const toPatch = ref<Array<Array<number>>>([[],[],[]])
    const iconWidthAndHeight = [25, 40]

    const patchLatLng = (latLng: any, id: number) => {
      toPatch.value[id] = [latLng.lat, latLng.lng]
      //PATCH NEW VALUES TO ENDPOINT...
    }

    const addLocationPoint = () => {
      emit('addLocationPoint', searchedLocation.value)
    }

    const cancelLocationPoint = () => {
      emit('cancelLocationPoint', true)
    }

    const deleteLocationPoint = (index: any) => {
      emit('deleteLocationPoint', index)
    }

    const deleteMainLocationPoint = () => {
      searchedLocations.value.forEach((x: SearchedLocation, index: any) => {
        if (x.isMainLocation) {
          emit('deleteLocationPoint', index)
          hideWarning()
        }
      })
    }

    const checkMainLocation = (i: number) => {
      searchedLocations.value.forEach((s, index) => {
        if (index !== i) {
          s.isMainLocation = false
        }
      })
    }

    const addOnClick = (p: any) => {
      if (p.latlng) {
        emit('addOnClick', p.latlng)
      }
    }

    const centerUpdated = (center: any) => {
      emit('update:center', [center.lat,center.lng])
    }

    const doMapStuff = () => {
      let locs: Array<any> = []
      props.searchedLocations.forEach((location: any) => {
          locs.push(location.latLon)
          if (props.searchedLocations.length === 1) {
            locs.push(location.latLon)
            //NEEDS BETTER SOLUTION, WHEN ONLY ONE LOCATION ITS BUGGY
              locs.push([location.latLon[0]*1.0001,location.latLon[1]*1.0001])
          }
      })

      if (props.searchedLocations.length === 0) {
        //SET COORDS TO BELGIUM IF THERE ARE NO LOCATIONS
        locs.push([50.500479,4.6954777])
        locs.push([50.500480,4.6954778])
      }

      const markerBounds = latLngBounds([])

      locs.forEach((loc: any) => {
        markerBounds.extend(latLng(loc[0],loc[1]))
      })

      let map = myMap2.value.leafletObject
      
      if (map) {
        map.fitBounds([[markerBounds.getSouth(),markerBounds.getWest()],[markerBounds.getNorth(),markerBounds.getEast()]])
        // map.value.leafletObject.fitBounds([[10,10],[10,10]])
        
        if (props.searchedLocations.length === 0) {
            map.setZoom(7)
          } else {
            map.setZoom(map.getZoom() - 1);
          }
      }
    }

    setTimeout(() => {
      doMapStuff()
    }, 1)

    const setCoords = (coords: any, sL: any) => {
      sL.latLon = [coords.latlng.lat,coords.latlng.lng]
    }
    return {
      centerClickedLocation,
      deleteMainLocationPoint,
      cancelLocationPoint,
      deleteLocationPoint,
      iconWidthAndHeight,
      isWarningDisplayed,
      checkMainLocation,
      addLocationPoint,
      displayWarning,
      centerUpdated,
      patchLatLng,
      hideWarning,
      InputTypes,
      addOnClick,
      doMapStuff,
      toPatch,
      myMap2,
      setCoords
    }
  }
})
