
import { defineComponent } from 'vue'
import IImportant from '../icons/IImportant.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Message',
  components: {
    IImportant,
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    return { t }
  }
})
