
import LocationCreateSidebar from '../sideBars/LocationCreateSidebar.vue'
import ParentLeafletMap from '../cards/leaflet/ParentLeafletMap.vue'
import LeafletMap from '@/components/cards/leaflet/leafletMap.vue'
import { defineComponent, ref, PropType, watch } from 'vue'
import { useInfoBarHelper } from '@/helpers/infoBarHelper'
import { CustomButton } from 'vue-3-component-library'
import Message, { ColorState } from './message.vue'
import { Check } from '@/serializer/Check'
import IMarker from '../icons/IMarker.vue'
import IInfo from '../icons/IInfo.vue'
import { useI18n } from 'vue-i18n'
import { usePlaceAutocompleteHelper } from '@/helpers/placeAutocompleteHelper'
import { useDateHelper } from '@/helpers/dateHelper'

export default defineComponent({
  name: 'LocationComponent',
  components: {
    LocationCreateSidebar,
    ParentLeafletMap,
    CustomButton,
    LeafletMap,
    Message,
    IMarker,
    IInfo
  },
  props: {
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getTranslationCountry } = usePlaceAutocompleteHelper()
    const { dateFromLocalisedString } = useDateHelper()
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const child = ref<any>(null)
    const isReloading = ref<boolean>(false)
    const createSidebar = ref<any>({ state: 'hide' })
    const { sidebar } = useInfoBarHelper()

    const openLocationCreateSidebar = (): void => {
      document.body.classList.add('overflow-hidden')
      createSidebar.value = { state: 'new' }
    }

    const actionSuccess = (action: { data: any; action: string }) => {
      if (action.action === 'PATCH') {
        reloadMapComponent()
        emit('rl', true)
      }
      if (action.action === 'CLOSE') {
        reloadMapComponent()
      }
    }

    const rl = () => {
        emit('rl', true)
    }

    const reloadMapComponent = () => {
      // isReloading.value = true
      // setTimeout(() => {
      //   isReloading.value = false
      // }, 1)
      // console.log('reload component...')
    }

    watch(
      () => sidebar.value.state,
      () => {
        reloadMapComponent()
      }
    )

    const edit = (parentLocation: any) => {
      createSidebar.value = { state: 'edit', entity: parentLocation }
    }

    const centerInChildComponent = (loc: any) => {
      child.value.centerClickedLocation(loc.latitude,loc.longitude)
    }



    return {
      getTranslationCountry,
      openLocationCreateSidebar,
      centerInChildComponent,
      createSidebar,
      actionSuccess,
      isReloading,
      ColorState,
      edit,
      child,
      dateFromLocalisedString,
      t,
      rl
    }
  },
})
