
import { LMap, LTileLayer, LMarker, LIcon, LPopup, LControl } from '@vue-leaflet/vue-leaflet'
import { CustomInput, CustomButtonSmall, InputTypes, Warning } from 'vue-3-component-library'
import { SearchedLocation } from '../../../serializer/SearchedLocation'
import { defineComponent, ref, PropType, toRefs } from 'vue'
import ICenter from '@/components/icons/ICenter.vue'
import { latLng } from 'leaflet'
import CustomPopup from './CustomPopup.vue'
import { Check } from '@/serializer/Check'
import { useI18n } from 'vue-i18n'
import 'leaflet/dist/leaflet.css'

export default defineComponent({
  components: {
    CustomPopup,
    LTileLayer,
    CustomInput,
    LControl,
    LMarker,
    LPopup,
    LIcon,
    LMap,
    Warning,
    CustomButtonSmall,
    ICenter
  },
  props: {
    parentLocations: {
      type: Object as PropType<Array<any>>,
      required: false,
      default: () => {
        return []
      },
    },
    locations: {
      type: Object as PropType<Array<SearchedLocation>>,
      required: false,
      default: () => {
        return []
      },
    },
    isCreating: Boolean,
    searchedLocation: {
      type: Object as PropType<SearchedLocation>,
      default: () => {
        return {}
      },
    },
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
    searchedLocations: {
      type: Object as PropType<Array<SearchedLocation>>,
      default: () => {
        return {}
      },
    },
  },
  setup(props, { emit }) {
    const myMap = ref<any>(null)
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    // THIS APPLICATION USES VUE3-LEAFLET BUT DOCUMENTATION IS ALMOST THE SAME AS VUE2-LEAFLET
    // https://vue2-leaflet.netlify.app/quickstart/
    const isWarningDisplayed = ref<Boolean>(false)
    const displayWarning = () => {
      isWarningDisplayed.value = true
    }

    const centerClickedLocation = (lat: any, lng: any) => {      
      let map = myMap.value.leafletObject
      map.closePopup()
      for (const [key, value] of Object.entries(map._layers)) {
        if (map._layers[key].options['pane'] === 'markerPane') {
          if (map._layers[key]._latlng.lat === lat && map._layers[key]._latlng.lng) {
            map._layers[key].openPopup()
          }
        }
      }
      map.flyTo(latLng(lat, lng), 14)
    }

    const doMapStuff = () => {
      let map: any = myMap.value.leafletObject
      let markers: any = []
      props.parentLocations.forEach((parentLocation: any) => {
        parentLocation.locations.forEach((location: any) => {
          markers.push([location.latitude, location.longitude]);
          if (parentLocation.locations.length === 1) {
            map.flyTo(latLng(location.latitude, location.longitude), 14)
          }
        })
      })
      if (markers.length > 1) {
        map.fitBounds(markers);
        map.zoomOut();
      }

      if (markers.length === 0) {
        map.flyTo(latLng(50.500480, 4.6954778), 7)
      }
    }

    const hideWarning = () => {
      isWarningDisplayed.value = false
    }
    const { searchedLocations } = toRefs(props)

    const toPatch = ref<Array<Array<number>>>([[], [], []])
    const iconWidthAndHeight = [25, 40]

    const patchLatLng = (latLng: any, id: number) => {
      toPatch.value[id] = [latLng.lat, latLng.lng]
      //PATCH NEW VALUES TO ENDPOINT...
    }

    const deleteLocationPoint = (index: any) => {
      emit('deleteLocationPoint', index)
    }

    const deleteMainLocationPoint = () => {
      searchedLocations.value.forEach((x: SearchedLocation, index: any) => {
        if (x.isMainLocation) {
          emit('deleteLocationPoint', index)
          hideWarning()
        }
      })
    }

    const edit = (parentLocation: any) => {
      emit('edit', parentLocation)
    }

    const init = () => {
      setTimeout(() => {
        if (myMap.value && myMap.value.leafletObject && myMap.value.leafletObject.fitBounds ) {
          doMapStuff()
        } else {
          init()
        }
      }, 50)
    }

    init()

    const rl = () => {
        emit('rl', true)
    }

    return {
      rl,
      centerClickedLocation,
      deleteMainLocationPoint,
      deleteLocationPoint,
      iconWidthAndHeight,
      isWarningDisplayed,
      displayWarning,
      patchLatLng,
      hideWarning,
      InputTypes,
      toPatch,
      edit,
      t,
      doMapStuff,
      myMap,
    }
  },
})
