
import ParticipantOverview from '../semantics/ParticipantOverview.vue'
import LocationComponent from '../semantics/LocationComponent.vue'
import { InputTypes, CustomInput } from 'vue-3-component-library'
import OverviewFiles from '@/components/upload/overviewFiles.vue'
import Message, { ColorState } from '../semantics/message.vue'
import DateField from '@/components/semantics/DateField.vue'
import { Check, CheckTypes } from '../../serializer/Check'
import CommentCheck from '../semantics/CommentCheck.vue'
import { defineComponent, PropType } from 'vue'
import CheckComponent from './checks/check.vue'
import VSwitch from '@lmiller1990/v-switch'
import { Visum } from '@/serializer/Visum'
import NumberCheck from '../semantics/NumberCheck.vue'

export default defineComponent({
  name: 'ConcernSwitch',
  components: {
    ParticipantOverview,
    LocationComponent,
    CheckComponent,
    OverviewFiles,
    CommentCheck,
    CustomInput,
    InputTypes,
    DateField,
    VSwitch,
    Message,
    NumberCheck,
  },
  props: {
    checkType: String,
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
    camp: {
      type: Object as PropType<Visum>,
      required: true,
    },
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const rl = () => {
      emit('rl', true)
    }

    return {
      CheckTypes,
      ColorState,
      InputTypes,
      rl,
    }
  },
})
