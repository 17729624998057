
import { defineComponent, ref } from 'vue'
import IChevonDown from '@/components/icons/IChevonDown.vue'
import IChevonUp from '@/components/icons/IChevonUp.vue'
import ICalendar from '@/components/icons/ICalendar.vue'
import { useI18n } from 'vue-i18n'
import PassportMenuItem from './PassportMenuItem.vue'

export default defineComponent({
  components: { IChevonDown, IChevonUp, ICalendar, PassportMenuItem},
  name: 'PassportMenu',
  props: {
    title: String
  },
  setup () {
    window.scrollTo({ top: 0, behavior: 'auto' })
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const isMenuOpen = ref<boolean>(false)

    const toggle = () => {
      isMenuOpen.value = !isMenuOpen.value
    }

    return {
      t,
      toggle,
      isMenuOpen
    }
  }
})
