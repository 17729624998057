import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-4 flex flex-col gap-3" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_date_field_deadline = _resolveComponent("date-field-deadline")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_side_bar, {
      width: "max-w-xl",
      isOverflowHidden: _ctx.isOverflowHidden,
      selection: _ctx.selected,
      "is-display": _ctx.sideBarState.state !== 'hide',
      "is-edit": _ctx.sideBarState.state === 'edit',
      name: "Deadline",
      title: _ctx.title,
      onHideSidebar: _ctx.closeSideBar
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          id: "DeadlineForm",
          ref: "formDiv",
          class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit', 'd-none': _ctx.sideBarState.state === 'list' }, "flex-col relative overflow-y-scroll h-full px-4 pt-3"]),
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_custom_input, {
                isSubmitting: _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "title",
                label: "Titel"
              }, null, 8, ["isSubmitting", "type"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_date_field_deadline, {
                isWithRange: false,
                class: "my-2"
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_custom_input, {
                textAreaWidth: "w-full",
                isSubmitting: _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT_AREA,
                name: "info",
                label: "Info"
              }, null, 8, ["isSubmitting", "type"])
            ])
          ])
        ], 34)
      ]),
      _: 1
    }, 8, ["isOverflowHidden", "selection", "is-display", "is-edit", "title", "onHideSidebar"])
  ]))
}