
import { SidebarState, useInfoBarHelper } from '@/helpers/infoBarHelper'
import IInfoGreen from '../icons/IInfoGreen.vue'
import { defineComponent, ref } from 'vue'
import { usePhoneHelper } from '@/helpers/phoneHelper'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  name: 'InformationSideBar',
  components: {
    IInfoGreen,
  },
  props: {
    isOverflowHidden: Boolean,
  },
  setup() {
    const { info, setInfo, sidebar, defaultInfo } = useInfoBarHelper()
    const { selectedGroup } = useGroupAndYears()

    const closeSideBar: () => void = () => {
      setInfo()
      sidebar.value.state = SidebarState.CLOSED
    }

    const openSideBar: () => void = () => {
      sidebar.value.state = SidebarState.OPEN
    }

    if (info.value) {
      closeSideBar()
    }

    const { checkIfIsMobileSize } = usePhoneHelper()


    if (info.value !== defaultInfo.value && !checkIfIsMobileSize()) {
      openSideBar()
    }

    return {
      closeSideBar,
      SidebarState,
      openSideBar,
      sidebar,
      info,
    }
  },
})
