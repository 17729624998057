
import HeaderSubcategoryCard from '../semantics/HeaderSubcategoryCard.vue'
import CheckSwitch from '@/components/cards/CheckSwitch.vue'
const LitepieDatepicker = require('litepie-datepicker')
import { SubCategory } from '@/serializer/SubCategory'
import { defineComponent, PropType } from 'vue'
import { Check } from '@/serializer/Check'
import { Visum, VisumStates } from '@/serializer/Visum'
import Feedback, {StatusFeedbackState} from '../semantics/Feedback.vue'
import Message, {ColorState} from '../semantics/message.vue'
import useGroupAndYears from '@/composable/useGroupAndYears'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'BaseSubcategoryCard',
  components: {
    'check-switch': CheckSwitch,
    'header-subcategory-card': HeaderSubcategoryCard,
    'litepie-datepicker': LitepieDatepicker,
    Feedback,
    Message
  },
  props: {
    subCategory: {
      type: Object as PropType<SubCategory>,
      required: true,
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
    checks: Array as PropType<Check[]>,
  },
  setup(props, { emit }) {
    const { selectedGroup } = useGroupAndYears()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const openSidebar = () => {
      emit('openSidebar')
    }

    const rl = () => {
      emit('rl', true)
    }

    return {
      StatusFeedbackState,
      selectedGroup,
      openSidebar,
      VisumStates,
      ColorState,
      rl,
      t,
    }
  },
})
