
import { defineComponent, PropType, ref } from 'vue'
import IChecked from '../icons/IChecked.vue'
import IEmptyCheck from '../icons/IEmptyCheck.vue'
import { useI18n } from 'vue-i18n'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { CampVisumRepository } from '@/repositories/CampVisumRepository'
import { SubCategory } from '@/serializer/SubCategory'
import { useNotification } from '@/composable/useNotification'
import useGroupAndYears from '@/composable/useGroupAndYears'

export enum ColorState {
  SUCCES = 'SUCCES',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  GRAY = 'GRAY',
}

export type Color = {
  state: ColorState
}

export default defineComponent({
  components: { IEmptyCheck, IChecked },
  name: 'Message',
  props: {
    title: {
      type: String,
      required: true,
      default: 'default title',
    },
    text: String,
    color: {
      type: Object as PropType<Color>,
      required: false,
      default: () => {
        return { state: ColorState.SUCCES }
      },
    },
    hasCheck: Boolean,
    subCategory: {
      type: Object as PropType<SubCategory>,
      required: true,
    }
  },
  setup(props, {emit}) {
    const { selectedGroup } = useGroupAndYears()
    const isChecked = ref<boolean>(false)
    const { triggerNotification } = useNotification()

    const agree = () => {
      isChecked.value = true
      if (props.subCategory.id) {
        RepositoryFactory.get(CampVisumRepository)
        .patchVisumHandleFeedback(selectedGroup.value.groupAdminId, props.subCategory.id)
        .then(() => {
          triggerNotification(t('engagement.feedback-ackknowledged'))
          rl()
        })
      }
    }

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const rl = () => {
      emit('rl', true)
    }
    
    return {
      ColorState,
      isChecked,
      agree,
      t
    }
  },
})
