
import { useNavigation } from '../../composable/useNavigation'
import { Category } from '../../serializer/Category'
import IEmptyCheck from '../icons/IEmptyCheck.vue'
import { defineComponent, PropType } from 'vue'
import IChecked from '../icons/IChecked.vue'
import { Visum, VisumStates } from '@/serializer/Visum'
import { useRoute } from 'vue-router'
import router from '@/router'
import useGroupAndYears from '@/composable/useGroupAndYears'
import {StatusFeedbackState} from '../semantics/Feedback.vue'
import ICheckedCross from '../icons/ICheckedCross.vue'
import ICheckWarning from '../icons/ICheckWarning.vue'
export default defineComponent({
  name: 'CategoryInfoCard',
  components: { IEmptyCheck, IChecked, ICheckedCross, ICheckWarning },
  props: {
    category: {
      type: Object as PropType<Category>,
      required: true,
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup() {
    const { navigateTowardsCategory } = useNavigation()
    const route = useRoute()
    const { selectedGroup } = useGroupAndYears()

    const navigateTowardsSubCategory = (category: Category) => {
      router.push('/kamp/' + route.params.campId.toString() + '/category/' + category.id)
    }

    return {
      navigateTowardsSubCategory,
      navigateTowardsCategory,
      route,
      VisumStates,
      selectedGroup,
      StatusFeedbackState
    }
  },
})
