
import IChevonDown from '../icons/IChevonDown.vue'
import { defineComponent, ref, watch } from 'vue'
import { Filter } from '../../serializer/Filter'
import Cross from '../icons/Cross.vue'
import { useI18n } from 'vue-i18n'
import IChevonUp from '../icons/IChevonUp.vue'
import { usePhoneHelper } from '@/helpers/phoneHelper'

export default defineComponent({
  components: { Cross, IChevonDown, IChevonUp },
  name: 'ParticipantFilter',
  props: {
  },
  setup (props, { emit }) {
const { checkIfIsMobileSize } = usePhoneHelper()

    const isFilterMenuOpen = ref<boolean>(true)

    if (checkIfIsMobileSize()) {
      isFilterMenuOpen.value = false
    }

    const closeFilterMenu = () => {
      if (checkIfIsMobileSize()) {
        isFilterMenuOpen.value = false
      }
    }

    const toggleFilterMenu = () => {
      isFilterMenuOpen.value = !isFilterMenuOpen.value
    }
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const options = ref<any>([{value: 'M', label: t('sidebars.participant-sidebar.filter.man')}, {value: 'V', label: t('sidebars.participant-sidebar.filter.woman')}, {value: 'X', label: t('sidebars.participant-sidebar.filter.other')}])
    const filter = ref<Filter>({ gender: '', ageMin: '', ageMax: '', type: '' })

    watch(() => filter.value.gender, () => {
      emit('changedFilters', filter.value)
    })
    
    const changedAge = () => {
      emit('changedFilters', filter.value)
    }

    const clearFilters = () => {
      filter.value.gender = ''
      filter.value.ageMin = ''
      filter.value.ageMax = ''
      emit('changedFilters', filter.value)
    }

    return {
      checkIfIsMobileSize,
      isFilterMenuOpen,
      toggleFilterMenu,
      closeFilterMenu,
      clearFilters,
      changedAge,
      options,
      filter,
      t
    }
  }
})
