
import { useNotification } from '@/composable/useNotification'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'forbidden',
  setup() {
    const { isForbidden } = useNotification()
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    return {
      isForbidden,
      t
    }
  },
})
