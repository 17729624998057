import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "px-3"
}
const _hoisted_3 = {
  key: 1,
  class: "px-3"
}
const _hoisted_4 = {
  key: 2,
  class: "px-3 mt-3"
}
const _hoisted_5 = {
  key: 3,
  class: "bg-gray my-3 border-l-4 border-black"
}
const _hoisted_6 = {
  key: 4,
  class: "bg-gray my-3 border-l-4 border-black"
}
const _hoisted_7 = {
  key: 5,
  class: "bg-gray mt-3 border-l-4 border-black"
}
const _hoisted_8 = {
  key: 6,
  class: "bg-gray my-3 border-l-4 border-black"
}
const _hoisted_9 = {
  key: 7,
  class: "bg-gray my-3 border-l-4 border-black"
}
const _hoisted_10 = {
  key: 8,
  class: "px-3 mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_component = _resolveComponent("check-component")!
  const _component_date_field = _resolveComponent("date-field")!
  const _component_comment_check = _resolveComponent("comment-check")!
  const _component_location_component = _resolveComponent("location-component")!
  const _component_overview_files = _resolveComponent("overview-files")!
  const _component_participant_overview = _resolveComponent("participant-overview")!
  const _component_number_check = _resolveComponent("number-check")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.check.id
  }, [
    (_ctx.checkType === _ctx.CheckTypes.SimpleCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_check_component, { check: _ctx.check }, null, 8, ["check"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.DurationCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createBlock(_component_date_field, {
            class: "my-2",
            key: _ctx.section,
            check: _ctx.check
          }, null, 8, ["check"]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.CommentCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_comment_check, { check: _ctx.check }, null, 8, ["check"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.CampLocationCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_location_component, {
            check: _ctx.check,
            onRl: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rl($event)))
          }, null, 8, ["check"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.LocationCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_location_component, {
            check: _ctx.check,
            onRl: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rl($event)))
          }, null, 8, ["check"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.FileUploadCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_overview_files, {
            visum: _ctx.visum,
            check: _ctx.check,
            onRl: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rl($event)))
          }, null, 8, ["visum", "check"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.MemberCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_participant_overview, {
            visum: _ctx.visum,
            check: _ctx.check,
            onRl: _cache[3] || (_cache[3] = ($event: any) => (_ctx.rl($event)))
          }, null, 8, ["visum", "check"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.ParticipantCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_participant_overview, {
            visum: _ctx.visum,
            check: _ctx.check,
            onRl: _cache[4] || (_cache[4] = ($event: any) => (_ctx.rl($event)))
          }, null, 8, ["visum", "check"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.checkType === _ctx.CheckTypes.NumberCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_number_check, {
            check: _ctx.check,
            onRl: _cache[5] || (_cache[5] = ($event: any) => (_ctx.rl($event)))
          }, null, 8, ["check"])
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}