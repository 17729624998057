import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "mt-3 md:col-span-2 xs:col-span-1 text-center",
  style: {"max-width":"261px"}
}
const _hoisted_2 = { class: "bg-green text-white rounded-sm" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  style: {"width":"fit-content"},
  class: "m-auto"
}
const _hoisted_5 = {
  key: 1,
  style: {"width":"fit-content"},
  class: "m-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_chevon_up = _resolveComponent("i-chevon-up")!
  const _component_i_chevon_down = _resolveComponent("i-chevon-down")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        class: _normalizeClass(["px-3", (!_ctx.isdisplayCallToAction) ? 'text-md mb-1 pt-1' : 'text-xl pt-3 mb-3'])
      }, _toDisplayString(_ctx.t('pages.kampvisum-overview.call-to-action-title')), 3),
      (_ctx.isdisplayCallToAction || _ctx.visums.length === 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.t('pages.kampvisum-overview.call-to-action'),
            class: "px-3 pb-3 text-left"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.visums.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleIsdisplayCallToAction())),
            class: "text-center border-t py-0.5 cursor-pointer"
          }, [
            (_ctx.isdisplayCallToAction)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_i_chevon_up)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isdisplayCallToAction)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_i_chevon_down)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}