import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  id: "locations-container",
  class: "p-3 flex flex-col gap-3"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_passport_nav_header = _resolveComponent("passport-nav-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_i_marker = _resolveComponent("i-marker")!
  const _component_i_shield = _resolveComponent("i-shield")!
  const _component_location_list_item = _resolveComponent("location-list-item")!
  const _component_passport_menu = _resolveComponent("passport-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_passport_nav_header, {
      visum: _ctx.visum,
      title: _ctx.t('passport.locations')
    }, null, 8, ["visum", "title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_loader, {
        color: "lightGreen",
        size: "20",
        isLoading: _ctx.isFetchingVisum
      }, null, 8, ["isLoading"])
    ]),
    (_ctx.visum)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visum.categorySet.categories, (category) => {
            return (_openBlock(), _createElementBlock("div", { key: category }, [
              (category.categoryParent.name === 'logistics' || category.categoryParent.name === 'safety')
                ? (_openBlock(), _createBlock(_component_passport_menu, {
                    key: 0,
                    title: category.categoryParent.label
                  }, {
                    "title-icon": _withCtx(() => [
                      (category.categoryParent.name === 'logistics')
                        ? (_openBlock(), _createBlock(_component_i_marker, { key: 0 }))
                        : _createCommentVNode("", true),
                      (category.categoryParent.name === 'safety')
                        ? (_openBlock(), _createBlock(_component_i_shield, { key: 1 }))
                        : _createCommentVNode("", true)
                    ]),
                    data: _withCtx(() => [
                      (category.categoryParent.name === 'logistics' || category.categoryParent.name === 'safety')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (!_ctx.checkIfLocationsAvailable(category.subCategories))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t('checks.location-check.no-locations')), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.subCategories, (subCategory) => {
                        return (_openBlock(), _createElementBlock("div", { key: subCategory }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subCategory.checks, (check) => {
                            return (_openBlock(), _createElementBlock("div", { key: check }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(check.value.locations, (location) => {
                                return (_openBlock(), _createElementBlock("div", { key: location }, [
                                  _createVNode(_component_location_list_item, { location: location }, null, 8, ["location"])
                                ]))
                              }), 128))
                            ]))
                          }), 128))
                        ]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["title"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}