
import PassportOverviewItem from '@/components/semantics/PassportOverviewItem.vue'
import PassportNavHeader from '@/components/semantics/PassportNavHeader.vue'
import PassportMenu from '@/components/semantics/PassportMenu.vue'
import IPhoneGreen from '@/components/icons/IPhoneGreen.vue'
import { useCampHelper } from '@/helpers/campHelper'
import IShield from '@/components/icons/IShield.vue'
import IMarker from '@/components/icons/IMarker.vue'
import ITime from '@/components/icons/ITime.vue'
import { defineComponent, ref } from 'vue'
import { Visum } from '@/serializer/Visum'
import { useI18n } from 'vue-i18n'
import LocationListItem from '@/components/semantics/LocationListItem.vue'
import { SubCategory } from '@/serializer/SubCategory'
import { Check } from '@/serializer/Check'
import { Loader } from 'vue-3-component-library'

export default defineComponent({
  components: {
    Loader,
    PassportOverviewItem, 
    PassportNavHeader, 
    PassportMenu, 
    IPhoneGreen, 
    IShield, 
    ITime, 
    IMarker,
    LocationListItem
  },
  name: 'PassportLocationsOverview',
  setup() {
    const isFetchingVisum = ref<boolean>(true)

    const { getCampByRouteParam } = useCampHelper()
    const visum = ref<Visum>()

    getCampByRouteParam().then((v: Visum) => {
      visum.value = v
      isFetchingVisum.value = false
    })

    const checkIfLocationsAvailable = (subCategories: SubCategory[]) => {
      const areAvailable = ref<boolean>(false)
      subCategories.forEach((sub) => {
        if (sub.checks) {
          sub.checks.forEach((check: Check) => {
            if ((check.checkParent?.checkType.checkType === 'CampLocationCheck' || check.checkParent?.checkType.checkType === 'LocationCheck') && (check.value.locations.length !== 0)) {
              areAvailable.value = true
            }
          })
        }
      })

      return areAvailable.value
    }

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    return {
      t,
      visum,
      checkIfLocationsAvailable,
      isFetchingVisum
    }
  },
})
