import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_required = _resolveComponent("required")!
  const _component_multi_select = _resolveComponent("multi-select", true)!
  const _component_error_message = _resolveComponent("error-message")!

  return (_ctx.options)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("strong", null, _toDisplayString(_ctx.label), 513), [
            [_vShow, _ctx.label]
          ]),
          _createElementVNode("strong", null, [
            (_ctx.label)
              ? (_openBlock(), _createBlock(_component_required, {
                  key: 0,
                  class: "inline",
                  rules: _ctx.rules
                }, null, 8, ["rules"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["min-w-0", {
        'text-lightGray animate-pulse2 bg-lightGray': _ctx.isLoading,
      }])
        }, [
          _createVNode(_component_multi_select, {
            preserveSearch: false,
            id: _ctx.id,
            ref: "multiselect",
            modelValue: _ctx.inputValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            name: _ctx.id,
            "value-prop": _ctx.valueProp,
            disabled: _ctx.disabled || _ctx.isLoading,
            "filter-results": true,
            "min-chars": 1,
            "resolve-on-load": true,
            delay: 500,
            placeholder: _ctx.placeholder,
            "track-by": _ctx.trackBy,
            label: _ctx.trackBy,
            searchable: _ctx.searchable,
            search: true,
            object: _ctx.object,
            "allow-empty": false,
            multiple: false,
            canClear: _ctx.canClear,
            canDeselect: _ctx.canDeselect,
            options: 
          _ctx.searchableByApi
            ? async function (query) {
                return _ctx.fetchSearchData(query)
              }
            : _ctx.options
        ,
            "no-options-text": "-geen resultaten-"
          }, null, 8, ["id", "modelValue", "name", "value-prop", "disabled", "placeholder", "track-by", "label", "searchable", "object", "canClear", "canDeselect", "options"]),
          _createVNode(_component_error_message, {
            class: "text-red font-light ml-1 mt-1 text-sm inline-block",
            name: _ctx.id
          }, null, 8, ["name"])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}