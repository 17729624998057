
import { ParticipantCheckRepository } from '@/repositories/ParticipantCheckRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { Member } from '../../serializer/Member'
import { defineComponent, PropType, watch, ref } from 'vue'
import IPerson from '../icons/IPerson.vue'
import IPencil from '../icons/IPencil.vue'
import { Check } from '@/serializer/Check'
import ITrash from '../icons/ITrash.vue'
import IInfo from '../icons/IInfo.vue'
import { useI18n } from 'vue-i18n'
import { usePhoneHelper } from '@/helpers/phoneHelper'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  name: 'MemberItem',
  components: {
    IPerson,
    IInfo,
    IPencil,
    ITrash,
  },
  props: {
    participant: {
      type: Object as PropType<Member>,
      required: true,
      default: () => {
        return {}
      },
    },
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { selectedGroup } = useGroupAndYears()
    const { checkIfIsMobileSize } = usePhoneHelper()
    const deleteFromList = async (p: Member) => {
      if (props.check.id) {
        await RepositoryFactory.get(ParticipantCheckRepository)
          .removeParticipantFromList(selectedGroup.value.groupAdminId, props.check.id, p.id)
          .then(() => {
            emit('actionSuccess', 'DELETE')
          })
      }
    }

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const openEditForm = (m: Member) => {
      emit('openSidebarToEdit', m)
    }

    const isPatchingPayment = ref<boolean>(false)

    watch(() => props.participant.hasPaid, () => {
      if (props.check.id && !isPatchingPayment.value) {
        isPatchingPayment.value = true
        RepositoryFactory.get(ParticipantCheckRepository)
          .toggleHasPaid(selectedGroup.value.groupAdminId, props.check.id, props.participant.wrapperId)
          .then(() => {
            isPatchingPayment.value = false
          })
      }
    })


    const isMin21 = (participant: Member) => {
      const currentYear = new Date().getFullYear();
      var x = currentYear - Number(participant.birthDate.slice(0,4))
      if (x >= 21 ) {
        return true
      } else {
        return false
      }
    }

    return {
      checkIfIsMobileSize,
      isPatchingPayment,
      deleteFromList,
      openEditForm,
      t,
      isMin21
    }
  },
})
