
import { defineComponent } from 'vue'
import { useNotification } from '../../composable/useNotification'

export default defineComponent({
  name: 'Notification',
  setup () {
    const { isDisplayingNotification, message, color } = useNotification()
    return {
      isDisplayingNotification,
      message,
      color
    }
  }
})
