import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38a25de0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "w-100 mt-4" }
const _hoisted_4 = { class: "w-100 mt-4" }
const _hoisted_5 = { class: "w-100 mt-4" }
const _hoisted_6 = { class: "w-100 mt-4" }
const _hoisted_7 = { class: "w-100 mt-4" }
const _hoisted_8 = { class: "w-100 mt-4" }
const _hoisted_9 = { class: "w-100 mt-4" }
const _hoisted_10 = { class: "w-100 mt-4" }
const _hoisted_11 = { class: "w-100 mt-4" }
const _hoisted_12 = { class: "w-100 mt-4" }
const _hoisted_13 = {
  class: "mt-5 py-4 sticky bottom-0 bg-white pl-3",
  style: {"margin-left":"-20px","margin-right":"-20px"}
}
const _hoisted_14 = { class: "px-4 pt-4 pb-1" }
const _hoisted_15 = {
  key: 0,
  class: "mx-1 mb-72 overflow-y-auto"
}
const _hoisted_16 = { class: "mx-4" }
const _hoisted_17 = { class: "mt-5 py-4 px-4 absolute bottom-0 bg-white w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_custom_button_small = _resolveComponent("custom-button-small")!
  const _component_participant_filter = _resolveComponent("participant-filter")!
  const _component_search_input = _resolveComponent("search-input")!
  const _component_member_sidebar_item = _resolveComponent("member-sidebar-item")!
  const _component_CustomButtonSmall = _resolveComponent("CustomButtonSmall")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_side_bar, {
      width: "max-w-2xl",
      maxWidth: "max-w-2xl",
      isOverflowHidden: true,
      selection: _ctx.selected,
      "is-display": _ctx.sideBarState.state !== 'hide',
      "is-edit": _ctx.sideBarState.state === 'edit',
      name: "ParticipantSidebar",
      title: "Lid",
      options: _ctx.options,
      onOptions: _ctx.changeSideBar,
      onHideSidebar: _ctx.closeSideBar
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          id: "ParticipantForm",
          ref: "formDiv",
          class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit', 'd-none': _ctx.sideBarState.state === 'search' }, "flex-col relative overflow-y-scroll h-full px-4 pt-3"]),
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "firstName",
                label: _ctx.t('sidebars.participant-sidebar.firstName')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "lastName",
                label: _ctx.t('sidebars.participant-sidebar.lastName')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                name: "email",
                label: _ctx.t('sidebars.participant-sidebar.email')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                name: "phoneNumber",
                label: _ctx.t('sidebars.participant-sidebar.phone')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                name: "city",
                label: _ctx.t('sidebars.participant-sidebar.city')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                name: "postalCode",
                label: _ctx.t('sidebars.participant-sidebar.postcode')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                name: "street",
                label: _ctx.t('sidebars.participant-sidebar.street')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                maxlength: "5",
                name: "number",
                label: _ctx.t('sidebars.participant-sidebar.nr')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT,
                maxlength: "5",
                name: "letterBox",
                label: _ctx.t('sidebars.participant-sidebar.mailbox')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.DATE,
                rules: "required",
                name: "birthDate",
                label: _ctx.t('sidebars.participant-sidebar.birthdate')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isPatching,
                type: _ctx.InputTypes.TEXT_AREA,
                name: "comment",
                label: _ctx.t('sidebars.participant-sidebar.comment')
              }, null, 8, ["disabled", "type", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_custom_button_small, {
              isSubmitting: _ctx.isPatching,
              text: _ctx.sideBarState.state === 'edit' ? _ctx.t('sidebars.participant-sidebar.edit') : _ctx.t('sidebars.participant-sidebar.add')
            }, null, 8, ["isSubmitting", "text"])
          ])
        ], 34),
        _createElementVNode("form", {
          id: "SearchForm",
          ref: "searchFormDiv",
          class: _normalizeClass([{ 'd-none': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit', 'd-flex': _ctx.sideBarState.state === 'search' }, "flex-col h-full"]),
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_participant_filter, {
              ref: "filterChild2",
              onChangedFilters: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changedFilters($event)))
            }, null, 512)
          ]),
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.filterChild2.closeFilterMenu && _ctx.filterChild2.closeFilterMenu(...args))),
            class: "p-4"
          }, [
            _createVNode(_component_search_input, {
              filter: _ctx.filter,
              loading: _ctx.loading,
              "onUpdate:loading": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.loading) = $event)),
              name: "search",
              placeholder: _ctx.t('sidebars.participant-sidebar.search'),
              repository: _ctx.ParticipantRepository,
              onFetchedOptions: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fetchedSearchResults($event)))
            }, null, 8, ["filter", "loading", "placeholder", "repository"])
          ]),
          (_ctx.filterChild2 && (!_ctx.filterChild2.isFilterMenuOpen || _ctx.checkIfIsMobileSize() === false))
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fetchedMembers, (member, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: member,
                      class: _normalizeClass([{ 'border-t-2 border-black': index === 0 }, "py-1 w-full shadow-md border-b-2 border-black bg-white p-2 inline-block text-left d-flex flex-col justify-content-between"])
                    }, [
                      _createVNode(_component_member_sidebar_item, {
                        displayCheck: _ctx.displayCheck(_ctx.check.checkParent.isMultiple, member, _ctx.fetchedMembers),
                        member: member
                      }, null, 8, ["displayCheck", "member"])
                    ], 2))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_CustomButtonSmall, {
              disabled: !(_ctx.fetchedMembers.some((f) => f.isChecked === true)),
              isSubmitting: _ctx.isPatching,
              text: _ctx.t('sidebars.participant-sidebar.add')
            }, null, 8, ["disabled", "isSubmitting", "text"])
          ])
        ], 34)
      ]),
      _: 1
    }, 8, ["selection", "is-display", "is-edit", "options", "onOptions", "onHideSidebar"])
  ]))
}