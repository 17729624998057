
import { Member } from '@/serializer/Member'
import { defineComponent, PropType } from 'vue'
import { useDateHelper } from '@/helpers/dateHelper'

export default defineComponent({
  name: 'MemberSidebarItem',
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true
    },
    displayCheck: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { dateFromLocalisedString } = useDateHelper()
    const toggle = (member: Member) => {
      if (props.displayCheck) {
        member.isChecked = !member.isChecked
      }
    }

    return {
      dateFromLocalisedString,
      toggle
    }
  },
})
