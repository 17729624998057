import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "datefield-litepie",
  class: "custom-pad max-w-sm"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_litepie_datepicker = _resolveComponent("litepie-datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.check.checkParent.label), 1),
    (_ctx.isWithRange)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_litepie_datepicker, {
            i18n: "nl",
            "as-single": "",
            "use-range": "",
            modelValue: _ctx.dateValues,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateValues) = $event)),
            formatter: _ctx.formatter,
            separator: " - ",
            placeholder: " "
          }, null, 8, ["modelValue", "formatter"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_litepie_datepicker, {
            i18n: "nl",
            "as-single": "",
            modelValue: _ctx.dateValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateValue) = $event)),
            formatter: _ctx.formatter,
            placeholder: " "
          }, null, 8, ["modelValue", "formatter"])
        ]))
  ]))
}