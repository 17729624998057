import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex gap-3 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_empty_check = _resolveComponent("i-empty-check")!
  const _component_i_checked = _resolveComponent("i-checked")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-between px-3", {
      'border-l-8 border-red bg-lightRed': _ctx.color.state === _ctx.ColorState.DANGER,
      'border-l-8 border-lightGreen bg-lighterGreen': _ctx.color.state === _ctx.ColorState.SUCCES,
      'border-l-8 border-orange bg-lightOrange': _ctx.color.state === _ctx.ColorState.WARNING,
      'border-black bg-gray': _ctx.color.state === _ctx.ColorState.GRAY,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", {
          class: _normalizeClass(["text-lg m-0", {
            'text-red': _ctx.color.state === _ctx.ColorState.DANGER,
            'text-lightGreen': _ctx.color.state === _ctx.ColorState.SUCCES,
            'text-orange': _ctx.color.state === _ctx.ColorState.WARNING,
            'text-black': _ctx.color.state === _ctx.ColorState.GRAY,
          }])
        }, _toDisplayString(_ctx.title), 3)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
      ])
    ]),
    (_ctx.hasCheck)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.isChecked)
              ? (_openBlock(), _createBlock(_component_i_empty_check, {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.agree()))
                }))
              : _createCommentVNode("", true),
            (_ctx.isChecked)
              ? (_openBlock(), _createBlock(_component_i_checked, { key: 1 }))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, _toDisplayString(_ctx.t('engagement.ok')), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}