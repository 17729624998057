import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-624bb62b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hover-edit xs:rounded-md xs:shadow-md md:border-b-2 flex flex-col md:flex-row gap-3 md:gap-0 justify-between p-2.5 bg-white" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex gap-3 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "bg-red font-bold text-white rounded-full px-2"
}
const _hoisted_5 = {
  key: 1,
  class: "bg-red font-bold text-white rounded-full px-2"
}
const _hoisted_6 = { class: "flex xs:flex-row-reverse justify-between md:gap-16 items-center" }
const _hoisted_7 = {
  key: 1,
  class: "flex gap-3 items-center"
}
const _hoisted_8 = { class: "flex gap-2 items-center font-bold" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  class: "cursor-pointer m-0",
  for: "paid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_person = _resolveComponent("i-person")!
  const _component_i_pencil = _resolveComponent("i-pencil")!
  const _component_i_trash = _resolveComponent("i-trash")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_i_person),
        _createElementVNode("div", null, _toDisplayString(_ctx.participant.fullName), 1),
        (_ctx.participant.inactiveMember || !_ctx.participant.isMember)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t('checks.participant-check.inActiveMember')), 1))
          : _createCommentVNode("", true),
        (!_ctx.isMin21(_ctx.participant) && _ctx.check.checkParent.name === 'members_leaders_responsible_21_year_old')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t('checks.participant-check.not-21')), 1))
          : _createCommentVNode("", true),
        (!_ctx.participant.isMember)
          ? (_openBlock(), _createBlock(_component_i_pencil, {
              key: 2,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditForm(_ctx.participant))),
              class: "pencil"
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.check.checkParent.isMultiple && _ctx.checkIfIsMobileSize())
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteFromList(_ctx.participant))),
            class: "hover:text-red underline cursor-pointer"
          }, [
            _createVNode(_component_i_trash)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      ((_ctx.check.checkParent.isMultiple && !_ctx.checkIfIsMobileSize()))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteFromList(_ctx.participant))),
            class: "hover:text-red underline cursor-pointer"
          }, [
            _createVNode(_component_i_trash)
          ]))
        : _createCommentVNode("", true),
      ((_ctx.check.checkParent.name === 'members_leaders_members_members' && false))
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                disabled: _ctx.isPatchingPayment,
                class: "cursor-pointer",
                value: true,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.participant.hasPaid) = $event)),
                type: "checkbox",
                id: "paid",
                name: "paid"
              }, null, 8, _hoisted_9), [
                [_vModelCheckbox, _ctx.participant.hasPaid]
              ]),
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.t('checks.participant-check.paid')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}