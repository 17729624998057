import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "pt-3 border border-lightGray flex gap-0 flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_subcategory_card = _resolveComponent("header-subcategory-card")!
  const _component_message = _resolveComponent("message")!
  const _component_check_switch = _resolveComponent("check-switch")!
  const _component_feedback = _resolveComponent("feedback")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.subCategory.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_header_subcategory_card, {
        subCategory: _ctx.subCategory,
        onOpenSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSidebar()))
      }, null, 8, ["subCategory"]),
      ((_ctx.subCategory.approval === _ctx.StatusFeedbackState.APPROVED && _ctx.subCategory.feedback)
        || _ctx.subCategory.approval === _ctx.StatusFeedbackState.APPROVED_FEEDBACK 
        || _ctx.subCategory.approval === _ctx.StatusFeedbackState.DISAPPROVED
        || _ctx.subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_RESOLVED
        || _ctx.subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_READ)
        ? (_openBlock(), _createBlock(_component_message, {
            key: 0,
            class: "my-3",
            title: _ctx.t('engagement.feedback-dc'),
            text: _ctx.subCategory.feedback,
            color: 
        (_ctx.subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_RESOLVED) ? {state: _ctx.ColorState.SUCCES} : 
        (_ctx.subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_READ) ? {state: _ctx.ColorState.SUCCES} : 
        (_ctx.subCategory.approval === _ctx.StatusFeedbackState.APPROVED) ? {state: _ctx.ColorState.SUCCES} : 
        _ctx.subCategory.approval === _ctx.StatusFeedbackState.APPROVED_FEEDBACK ? {state: _ctx.ColorState.WARNING} : 
        _ctx.subCategory.approval === _ctx.StatusFeedbackState.DISAPPROVED ? {state: _ctx.ColorState.DANGER} : {state: _ctx.ColorState.SUCCES},
            hasCheck: (_ctx.subCategory.approval === _ctx.StatusFeedbackState.DISAPPROVED || _ctx.subCategory.approval === _ctx.StatusFeedbackState.APPROVED_FEEDBACK),
            subCategory: _ctx.subCategory,
            onRl: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rl($event)))
          }, null, 8, ["title", "text", "color", "hasCheck", "subCategory"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checks, (check) => {
        return (_openBlock(), _createBlock(_component_check_switch, {
          onRl: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rl($event))),
          key: check,
          visum: _ctx.visum,
          check: check,
          checkType: check.checkParent.checkType.checkType
        }, null, 8, ["visum", "check", "checkType"]))
      }), 128)),
      (_ctx.selectedGroup.isDistrictCommissioner && _ctx.visum.engagement.leaders && _ctx.visum.engagement.groupLeaders)
        ? (_openBlock(), _createBlock(_component_feedback, {
            key: 1,
            onRl: _cache[3] || (_cache[3] = ($event: any) => (_ctx.rl($event))),
            subCategory: _ctx.subCategory,
            visum: _ctx.visum
          }, null, 8, ["subCategory", "visum"]))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}