import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "z-2" }
const _hoisted_2 = { class: "mb-3 mt-0 text-xl font-semibold font-museo" }
const _hoisted_3 = {
  key: 0,
  class: "font-bold bg-lighterGreen p-2 -m-2",
  style: {"width":"fit-content"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "bg-lighterGreen p-2 -m-2"
}
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { class: "group-hover:underline mb-0 ml-0 text-md font-museo font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_checked = _resolveComponent("i-checked")!
  const _component_i_empty_check = _resolveComponent("i-empty-check")!
  const _component_i_checked_cross = _resolveComponent("i-checked-cross")!
  const _component_i_check_warning = _resolveComponent("i-check-warning")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTowardsCategory(_ctx.category.categoryParent.name, _ctx.visum, _ctx.category.id))),
    class: "p-3 cursor-pointer shadow-md rounded-md hover:bg-lighterGreen",
    style: {"height":"300px"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.category.categoryParent.label), 1),
      (
        (_ctx.selectedGroup.isDistrictCommissioner || _ctx.selectedGroup.isGroupLeader || _ctx.selectedGroup.isSectionLeader) 
        && _ctx.visum.engagement.leaders 
        && _ctx.visum.engagement.groupLeaders)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "DC"))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.subCategories, (subCategory) => {
        return (_openBlock(), _createElementBlock("div", {
          style: {"width":"fit-content"},
          onClick: _withModifiers(($event: any) => (_ctx.navigateTowardsSubCategory(_ctx.category, subCategory)), ["stop"]),
          key: subCategory,
          class: "d-flex gap-3 my-2.5 items-center group"
        }, [
          (
        (_ctx.selectedGroup.isDistrictCommissioner || _ctx.selectedGroup.isGroupLeader || _ctx.selectedGroup.isSectionLeader) 
        && _ctx.visum.engagement.leaders 
        && _ctx.visum.engagement.groupLeaders)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (subCategory.approval === _ctx.StatusFeedbackState.APPROVED)
                  ? (_openBlock(), _createBlock(_component_i_checked, { key: 0 }))
                  : _createCommentVNode("", true),
                (subCategory.approval === _ctx.StatusFeedbackState.UNDECIDED)
                  ? (_openBlock(), _createBlock(_component_i_empty_check, { key: 1 }))
                  : _createCommentVNode("", true),
                (subCategory.approval === _ctx.StatusFeedbackState.DISAPPROVED || subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_RESOLVED)
                  ? (_openBlock(), _createBlock(_component_i_checked_cross, { key: 2 }))
                  : _createCommentVNode("", true),
                (subCategory.approval === _ctx.StatusFeedbackState.APPROVED_FEEDBACK)
                  ? (_openBlock(), _createBlock(_component_i_check_warning, { key: 3 }))
                  : _createCommentVNode("", true),
                (subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_READ)
                  ? (_openBlock(), _createBlock(_component_i_check_warning, { key: 4 }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          ((subCategory.approval === _ctx.StatusFeedbackState.APPROVED) || (subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_READ))
            ? (_openBlock(), _createBlock(_component_i_checked, { key: 1 }))
            : _createCommentVNode("", true),
          (subCategory.approval === _ctx.StatusFeedbackState.DISAPPROVED)
            ? (_openBlock(), _createBlock(_component_i_checked_cross, { key: 2 }))
            : _createCommentVNode("", true),
          (subCategory.approval === _ctx.StatusFeedbackState.APPROVED_FEEDBACK)
            ? (_openBlock(), _createBlock(_component_i_check_warning, { key: 3 }))
            : _createCommentVNode("", true),
          (subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_RESOLVED)
            ? (_openBlock(), _createBlock(_component_i_checked, { key: 4 }))
            : _createCommentVNode("", true),
          (
        !(subCategory.approval === _ctx.StatusFeedbackState.DISAPPROVED) 
        && !(subCategory.approval === _ctx.StatusFeedbackState.APPROVED_FEEDBACK) 
        && !(subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_RESOLVED) 
        && !(subCategory.approval === _ctx.StatusFeedbackState.APPROVED)
        && !(subCategory.approval === _ctx.StatusFeedbackState.FEEDBACK_READ))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (subCategory.state === 'CHECKED')
                  ? (_openBlock(), _createBlock(_component_i_checked, { key: 0 }))
                  : _createCommentVNode("", true),
                (subCategory.state === 'UNCHECKED')
                  ? (_openBlock(), _createBlock(_component_i_empty_check, { key: 1 }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h4", _hoisted_7, _toDisplayString(subCategory.subCategoryParent.label), 1)
        ], 8, _hoisted_4))
      }), 128))
    ])
  ]))
}