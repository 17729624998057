
const LitepieDatepicker = require('litepie-datepicker').default
import { defineComponent, ref} from 'vue'

export default defineComponent({
  name: 'DateFieldDeadline',
  components: {
    'litepie-datepicker': LitepieDatepicker
  },
  setup () {
    const dateValue = ref('')

    const formatter = ref({
      date: 'DD MMM YYYY',
      month: 'MMM'
    })


    return {
      dateValue,
      formatter
    }
  }
})
