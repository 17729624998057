
import { CustomButton, Loader } from 'vue-3-component-library'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Warning',
  components: {
    'custom-button': CustomButton,
    loader: Loader
  },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    isDisplayed: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'red'
    },
    leftButton: {
      type: String,
      required: false
    },
    rightButton: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['leftButtonClicked', 'rightButtonClicked'],
  setup(props, context) {
    const rightButtonClicked = () => {
      context.emit('rightButtonClicked', true)
    }

    const leftButtonClicked = () => {
      context.emit('leftButtonClicked', true)
    }

    return {
      rightButtonClicked,
      leftButtonClicked
    }
  }
})
