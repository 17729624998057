
import RepositoryFactory from '@/repositories/repositoryFactory'
import AuthRepository from '@/repositories/authRepository'
import { RouteLocationNormalizedLoaded, Router } from 'vue-router'
import { breadcrumb, useNavigation } from '@/composable/useNavigation'
import { defineComponent, PropType } from 'vue'
import useGroupAndYears from '@/composable/useGroupAndYears'
import useVisum from '@/composable/useVisum'
import store from '@/store/store'
import MasterConfig from '@/models/config/masterConfig'
import { CustomButtonSmall } from 'vue-3-component-library'
import { useI18n } from 'vue-i18n'
import useAuthHelper from '@/helpers/authHelper'

export default defineComponent({
  name: 'BreadCrumb',
  components: {
    'custom-button-small': CustomButtonSmall,
  },
  props: {
    home: {
      type: String,
      required: false,
      default: '/',
    },
    route: {
      type: Object as PropType<RouteLocationNormalizedLoaded>,
      required: true,
    },
    router: {
      type: Object as PropType<Router>,
      required: true,
    },
    isInternetActive: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const route = props.route
    const { breadcrumbs } = useNavigation()
    const { selectedGroup, selectedYear, getYearsForGroup, unsetSelectedGroup } = useGroupAndYears()
    const { getVisums } = useVisum()
    const config: MasterConfig = store.getters.config
    const { logoutFromGA } = useAuthHelper()
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const navigateHome = async () => {
      props.router.push(props.home)
      // window.location.pathname = '/kampvisum-home'
      await getYearsForGroup(selectedGroup.value.groupAdminId)
      getVisums(selectedGroup.value, selectedYear.value)
    }

    const navigateToCrumb = (selectedBreadcrumb: breadcrumb) => {
      let link: string = ''

      for (let i = 0; i < breadcrumbs.value.length; i++) {
        link += '/' + breadcrumbs.value[i].name + '/' + (breadcrumbs.value[i].uuid ? breadcrumbs.value[i].uuid : '')
        if (breadcrumbs.value[i].name === selectedBreadcrumb.name) {
          break
        }
      }
      props.router.push(link)
    }

    const logout = async () => {
      await logoutFromGA()
      window.location.href = config.frontend.logoutUrl + config.frontend.baseUrl;
    }

    return {
      route,
      breadcrumbs,
      navigateHome,
      navigateToCrumb,
      selectedYear,
      logout,
      t,
      config
    }
  },
})
