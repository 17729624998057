
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {},
  name: 'PassportMenuItem',
  props: {
    title: String
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    return {
      t,
    }
  }
})
