
import IEmptyCheck from '@/components/icons/IEmptyCheck.vue'
import Tooltip from '@/components/semantics/Tooltip.vue'
import { Flag } from '@/serializer/Flag'
import { defineComponent, PropType} from 'vue'

export default defineComponent({
  name: 'Check',
  components: {
    Tooltip,
    IEmptyCheck,
  },
  props: {
    flag: {
      type: Object as PropType<Flag>,
      required: true
    },
    isUpdatingFlag: Boolean
  },
  setup(props, { emit }) {
    const toggle = (flag: Flag, state: boolean) => {
      if (!props.isUpdatingFlag) {
        flag.flag = state
        emit('toggle', flag)
      }
    }
    return {
      toggle,
    }
  },
})
