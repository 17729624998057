
import MultiSelect from '../../components/inputs/MultiSelect.vue'
import { useSectionsHelper } from '../../helpers/sectionsHelper'
import { useNavigation } from '../../composable/useNavigation'
import { useNotification } from '@/composable/useNotification'
import useGroupAndYears from '@/composable/useGroupAndYears'
import { SidebarState } from '@/helpers/infoBarHelper'
import NavigationItem from './NavigationItem.vue'
import { Loader } from 'vue-3-component-library'
import useVisum from '../../composable/useVisum'
import { Group } from '@/serializer/Group'
import ILogo from '../icons/ILogo.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { useInternetHelper } from '@/helpers/internetHelper'
import { usePermission } from '@/composable/usePermission'

export default defineComponent({
  components: {
    NavigationItem,
    'multi-select': MultiSelect,
    ILogo,
    Loader,
  },
  name: 'NavigationSideBar',
  setup() {
    const { isInternetActive } = useInternetHelper()
    const { can, hasRole } = usePermission()
    const route = useRoute()
    const { isFetchingVisums, visums, visumsAlphabetically } = useVisum()
    const { getSectionsTitle } = useSectionsHelper()
    const { navigateTowardsCategory, sidebar } = useNavigation()
    const { setSelectedGroup, getAvailableGroups, selectedGroup } = useGroupAndYears()
    const { isForbidden } = useNotification()
    
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const toggleSideBar: () => void = () => {
      if (sidebar.value.state === SidebarState.OPEN) {
        sidebar.value.state = SidebarState.CLOSED
        return
      }
      if (sidebar.value.state === SidebarState.CLOSED) {
        sidebar.value.state = SidebarState.OPEN
        return
      }
    }

    const closeSidebar = () => {
      sidebar.value.state = SidebarState.CLOSED
    }

    const home = () => {
      router.push('/kampvisum-home/')
    }

    const changeSelectedGroup = (group: Group) => {
      setSelectedGroup(group)
      if (!window.location.pathname.includes('kampvisum-home')) {
        home()
      }
    }

    return {
      navigateTowardsCategory,
      visumsAlphabetically,
      changeSelectedGroup,
      getAvailableGroups,
      getSectionsTitle,
      isInternetActive,
      isFetchingVisums,
      selectedGroup,
      toggleSideBar,
      SidebarState,
      closeSidebar,
      isForbidden,
      sidebar,
      visums,
      route,
      home,
      t,
      can,
      hasRole
    }
  },
})
