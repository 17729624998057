
import { defineComponent, PropType } from 'vue'
import IMarker from '../icons/IMarker.vue'
import { useDateHelper } from '@/helpers/dateHelper'

export default defineComponent({
  name: 'LocationListItem',
  components: {
    IMarker
  },
  props: {
    location: {
      type: Object as PropType<any>
    }
  },
  setup () {
    const { dateFromLocalisedString } = useDateHelper()
    const openInGoogleMaps = (l: any) => {
      window.open(`http://maps.google.com/?q=${l.latitude},${l.longitude}`);
    }

    return {
      openInGoogleMaps,
      dateFromLocalisedString
    }
  }
})
