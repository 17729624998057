
import { PostLocation } from '@/serializer/PostLocation'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ExistingLocationItem',
  props: {
    existingLocation: {
      type: Object as PropType<PostLocation>,
      required: true
    },
    displayCheck: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const toggle = (location: PostLocation) => {
      if (props.displayCheck) {
        location.isChecked = !location.isChecked
      }
    }
    return  {
      toggle
    }
  }
})
