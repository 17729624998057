export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp Central"])},
  "page-titles": {
    "kampvisum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht kampen"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen van je groep"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenten"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
    "non-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-leden"])},
    "dc-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht DC"])}
  },
  "pages": {
    "kampvisum-overview": {
      "change-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verander groep"])},
      "create-camp-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak nieuw kamp aan"])},
      "delete-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je het kamp wil verwijderen?"])},
      "delete-warning-button-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleer"])},
      "delete-warning-button-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwijder"])},
      "notification-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kamp is succesvol verwijderd"])},
      "notification-posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kamp is succesvol aangemaakt"])},
      "notification-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kamp is succesvol bewerkt"])},
      "call-to-action-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe werkt Camp Central?"])},
      "call-to-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Gebruik het menu links om je groep te selecteren <br> • Indien je extra takken wil toevoegen, voeg deze dan toe via 'instellingen van je groep' <br> • Klik vervolgens op de knop hierboven om een nieuw kamp aan te maken  <br> • Vul alle kampinformatie aan <br>"])}
    },
    "camp-overview": {
      
    },
    "settings": {
      "sections": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takken"])},
        "sections-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takken voor"])},
        "delete-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je deze tak wil verwijderen?"])},
        "delete-warning-button-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleer"])},
        "delete-warning-button-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwijder"])},
        "notification-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tak is succesvol verwijderd"])},
        "notification-posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tak is succesvol aangemaakt"])},
        "notification-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tak is succesvol bewerkt"])},
        "create-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maak een nieuwe tak aan"])},
        "start-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startleeftijd"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaar"])},
        "sidebar": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
          "form": {
            "section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taknaam"])},
            "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslacht"])},
            "genders": {
              "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
              "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrouw"])},
              "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemengd"])}
            },
            "age-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeftijdsgroep"])}
          },
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk"])},
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg toe"])}
        }
      },
      "deadlines": {
        "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belangrijk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadlines"])}
      }
    }
  },
  "sidebars": {
    "kampvisum-sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp voor "])},
      "sections-going": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takken die meegaan"])},
      "input-fields": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw"])},
        "existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaande kampen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam kamp"])},
        "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
        "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum"])}
      },
      "buttons": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg toe"])}
      }
    },
    "deadline-sidebar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadlines"])},
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terug"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hieronder vind je de enige belangrijke deadline momenteel, de kampregistratie. Zorg dat je deze zeker hebt ingevuld voor de vervaldatum. In een latere versie van CampCentral zal je zelf nog deadlines kunnen toevoegen."])},
      "actual-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "fill-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul aan"])},
      "notification-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline check is succesvol bewerkt"])},
      "vertical-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEADLINES"])}
    },
    "navigation-sidebar": {
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])}
    },
    "member-sidebar": {
      "select-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles selecteren"])}
    },
    "location-sidebar": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op locatie"])},
      "success-move-pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["locatie gevonden, versleep de pin naar de exacte locatie."])},
      "failed-move-pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["locatie niet gevonden, versleep de pin naar de exacte locatie."])},
      "loading-move-pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["locatie aan het zoeken."])},
      "form": {
        "atleast-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer minstens 1 locatie"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam locatie"])},
        "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactpersoon"])},
        "contactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "contactPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gsm-nummer"])},
        "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
        "search-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een adres of duid het aan op de kaart."])},
        "search-addres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek adres"])},
        "main-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde locatie"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
        "notification-patched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kamp is succesvol bewerkt"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
        "township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeente"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
        "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisnummer"])},
        "start-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin en eind datum"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze periode valt buiten de kampperiode."])}
      }
    },
    "participant-sidebar": {
      "filter": {
        "man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
        "woman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrouw"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
        "min-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min leeftijd"])},
        "max-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max leeftijd"])}
      },
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM-nummer"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
      "nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus"])},
      "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOEG TOE"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op naam"])}
    }
  },
  "checks": {
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "not-relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet relevant"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeg"])},
    "notification-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kamp is succesvol bewerkt"])},
    "location-check": {
      "main-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoofdlocatie"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerk"])},
      "add-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ voeg locatie toe"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek"])},
      "delete-warning-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdlocatie"])},
      "delete-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je de hoofdlocatie wil verwijderen?"])},
      "delete-warning-button-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleer"])},
      "delete-warning-button-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwijder"])},
      "no-locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen locaties"])}
    },
    "document-check": {
      "no-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen document"])},
      "add-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ voeg document(en) toe"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenten"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerd"])},
      "uploaded-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geüploade bestanden"])},
      "no-uploaded-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bestanden geüpload"])},
      "search-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek door bestaande bestanden"])},
      "notification-patched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestand(en) succesvol toegevoegd"])},
      "notification-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestand succesvol verwijderd uit de lijst"])},
      "upload-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BESTANDEN UPLOADEN"])},
      "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep je documenten hier naartoe, of klik hier om ze op te laden"])},
      "allowed-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enkel .jpg, .jpeg, .png, .webp, .odt, .ods, .odp, .docx, .pdf, .pptx, .xlsx, .doc"])},
      "file-too-big-part-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te groot bestand"])},
      "file-too-big-part-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. 20 MB"])},
      "file-type-incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit bestandstype is niet toegelaten"])}
    },
    "participant-check": {
      "no-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leeg"])},
      "no-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leeg"])},
      "hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verborgen"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ VOEG TOE"])},
      "add-without-plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOEG TOE"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ WIJZIG"])},
      "notification-patched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lid/leden succesvol toegevoegd"])},
      "notification-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lid succesvol verwijderd uit de lijst"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwijder"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op naam"])},
      "inActiveMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verzekerd?"])},
      "not-21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen 21"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betaald"])},
      "no-email-part-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan"])},
      "no-email-part-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet toevoegen, heeft geen e-mailadres in de Groepsadministratie"])}
    },
    "number-check": {
      "notification-patched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanpassingen opgeslagen"])}
    }
  },
  "no-camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen kampen"])},
  "forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt niet de juiste rechten om deze pagina te zien. Als je denkt dat dat fout is, contacteer dan je verantwoordelijke groepsadministratie (VGA) zodat die het kan goedzetten in de Groepsadministratie."])},
  "engagement": {
    "leaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiding"])},
    "group-leaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsleiding"])},
    "dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampengagement"])},
    "warning-text-leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik onderteken dit kamp in naam van de leidingsploeg en geef hierbij aan dat wij ons kamp goed hebben voorbereid. We willen dat het boeiend wordt voor iedereen en we willen onze inspanning volhouden."])},
    "warning-text-feedback-handled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik bevestig dat alle feedback is nagelezen en verwerkt"])},
    "warning-left-button-feedback-handled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback behandeld"])},
    "warning-right-button-feedback-handled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "warning-left-button-leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkoord"])},
    "warning-right-button-leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "warning-text-group-leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik onderteken dit kamp in naam van de groepsleiding. Ik heb een gesprek gehad met de leiding over dit kamp en heb er vertrouwen in dat de leidingsploeg het kamp goed heeft voorbereid. Ik geef hier ook bij aan dat er het hele kamp een 21-jarige verantwoordelijke aanwezig is."])},
    "warning-left-button-group-leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkoord"])},
    "warning-right-button-group-leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "warning-text-dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik onderteken dit kamp in naam van de dc (-ploeg). Ik heb met de groepsleiding (en eventueel met de takleiding) het gesprek over de kampvoorbereiding geëvalueerd. Ik heb er vertrouwen in dat de kampvoorbereiding goed is begeleid en dat het kamp goed voorbereid is. Daarenboven voldoet de voorbereiding aan alle formele vereisten."])},
    "warning-left-button-dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkoord"])},
    "warning-right-button-dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "warning-text-dc-disapprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC Ga je ermee akkoord dat je alles hebt nagelezen en het kamp hierbij afkeurt?"])},
    "warning-left-button-dc-disapprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFKEUREN"])},
    "warning-right-button-dc-disapprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "feedback-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok"])},
    "feedback-not-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opmerking"])},
    "feedback-declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgekeurd"])},
    "feedback-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedback bewerkt"])},
    "notes-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaar bewerkt"])},
    "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaar - enkel zichtbaar voor DC en Staf"])},
    "feedback-dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback DC"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "feedback-ackknowledged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback gecheckt"])},
    "approve-camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp goedkeuren"])},
    "feedback-handled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback verwerkt"])},
    "feedback-handled-extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp afgekeurd, de leiding moet nu eerst de feedback verwerken"])},
    "disapprove-camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp afkeuren"])}
  },
  "global-camp-state": {
    "title-ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp goedgekeurd"])},
    "msg-ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title-FEEDBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp met feedback"])},
    "msg-FEEDBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je DC heeft het kamp nagekeken en heeft nog opmerkingen. Klik op de categorie met het uitroepteken om de feedback van je DC te zien. Indien je de opmerkingen verwerkt hebt kan je bij de feedback rechts op OK klikken."])},
    "title-DISAPPROVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp afgekeurd"])},
    "msg-DISAPPROVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je DC heeft het kamp nagekeken en heeft nog opmerkingen. Klik op de categorie met het kruisje om de feedback van je DC te zien. Indien je de opmerkingen verwerkt hebt kan je bij de feedback rechts op OK klikken."])},
    "title-INPROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp in opbouw"])},
    "msg-INPROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title-READYFORDC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp klaar voor DC"])},
    "msg-READYFORDC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "passport": {
    "view-passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bekijk paspoort"])},
    "no-end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen einddatum ingesteld."])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locaties"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenten"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
    "menu-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
    "menu-fouriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fouriers"])},
    "no-fouriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen fouriers"])},
    "no-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen leden"])},
    "download-camp-offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download kamp offline met bestanden"])},
    "sync-camp-offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync camp offline met bestanden"])},
    "success-camp-offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp is synced offline"])}
  },
  "location-overview": {
    "filters": {
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van"])},
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot"])},
      "group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnaam (bevat)"])},
      "group-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnummer"])},
      "group-number-wip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnummer (is of begint met)"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek"])}
    },
    "check-camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk kamp"])}
  },
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacteer ons"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log uit"])},
  "duplicate_camp_responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubbele kampverantwoordelijken!"])},
  "dc-overview": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamp"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
    "camp-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie"])},
    "camp-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
  },
  "page-footer": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar overzicht"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vorige categorie"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volgende categorie"])}
  }
}