
import { SearchedLocation } from '@/serializer/SearchedLocation'
import IPersonGreen from '@/components/icons/IPersonGreen.vue'
import IPhoneGreen from '@/components/icons/IPhoneGreen.vue'
import IMailGreen from '@/components/icons/IMailGreen.vue'
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import useVisum from '@/composable/useVisum'
import { useDateHelper } from '@/helpers/dateHelper'

export default defineComponent({
  components: { IPersonGreen, IPhoneGreen, IMailGreen, },
  name: 'CampCustomPopup',
  props: {
    location: {
      type: Object as PropType<SearchedLocation>,
      required: false
    }
  },
  setup (props, { emit }) {
    const { navigateTowardsVisum } = useVisum()
    const { dateFromLocalisedString } = useDateHelper()
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    return  {
      t,
      navigateTowardsVisum,
      dateFromLocalisedString
    }
  }
})
