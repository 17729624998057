
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDateHelper } from '@/helpers/dateHelper'

export default defineComponent({
  name: 'CustomFooter',
  components: {},
  setup () {
    const { getCurrentYear } = useDateHelper()
    const currentYear = ref<string>(getCurrentYear())  
      const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })


    const navigate = () => {
      window.open(
            "https://www.scoutsengidsenvlaanderen.be/contact", "_blank");
    }
    return {
      currentYear,
      t
    }
  }
})
