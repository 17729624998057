import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-4 p-2 border-b border-black text-black"
}
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "flex justify-end gap-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_pencil = _resolveComponent("i-pencil")!
  const _component_cross = _resolveComponent("cross")!

  return (!_ctx.groupSection.hidden)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(_ctx.groupSection.name.name), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.genderLabel(_ctx.groupSection.name.gender)), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.t('pages.settings.sections.start-age')) + " " + _toDisplayString(_ctx.groupSection.name.ageGroup) + " " + _toDisplayString(_ctx.t('pages.settings.sections.year')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(_component_i_pencil, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.edit(_ctx.groupSection))),
              class: "text-green cursor-pointer"
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_cross, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.remove(_ctx.groupSection))),
              class: "text-red cursor-pointer"
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}