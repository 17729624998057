
import DeadlineCreateSidebar from '../sideBars/DeadlineCreateSidebar.vue'
import { CustomButtonSmall } from 'vue-3-component-library'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DeadlineSettings',
  components: {
    DeadlineCreateSidebar,
    CustomButtonSmall
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const createSidebar = ref<any>({state: 'hide'})


    const openDeadlineCreateSidebar = (): void => {
      createSidebar.value = {state: 'new'}
    }

    const actionSuccess = (action: string) => {
      if (action === 'POST') {
        // console.log('Kamp is succesvol aangemaakt')
      }
      if (action === 'UPDATE') {
        // console.log('Kamp is succesvol bewerkt')
      }
      // get deadlines again to update the data in the sidebar
    }

    return {
      t,
      createSidebar,
      actionSuccess,
      openDeadlineCreateSidebar
    }
  }
})
