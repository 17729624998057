
import { CustomButtonSmall, Warning, Loader } from 'vue-3-component-library'
import CampCallToAction from '@/components/semantics/campCallToAction.vue'
import CampSidebar from '../components/sideBars/CampSideBar.vue'
import RepositoryFactory from '@/repositories/repositoryFactory'
import CampInfoCard from '@/components/cards/CampInfoCard.vue'
import { CampVisumRepository } from '@/repositories/CampVisumRepository'
import MultiSelect from '../components/inputs/MultiSelect.vue'
import { useNotification } from '@/composable/useNotification'
import useGroupAndYears from '@/composable/useGroupAndYears'
import Forbidden from '@/components/semantics/Forbidden.vue'
import { useNavigation } from '@/composable/useNavigation'
import { SidebarState } from '@/helpers/infoBarHelper'
import useVisum from '@/composable/useVisum'
import { defineComponent, ref } from 'vue'
import { Visum } from '@/serializer/Visum'
import { useI18n } from 'vue-i18n'
import { useInternetHelper } from '@/helpers/internetHelper'
import { usePermission, permissions } from '@/composable/usePermission'

export default defineComponent({
  name: 'KampvisumHome',
  components: {
    'camp-info-card': CampInfoCard,
    'custom-button': CustomButtonSmall,
    'camp-side-bar': CampSidebar,
    'multi-select': MultiSelect,
    warning: Warning,
    Loader,
    CampCallToAction,
    Forbidden,
  },
  setup() {
    const { isInternetActive } = useInternetHelper()
    const campSideBarState = ref<any>({ state: 'hide', entity: {} })
    const isWarningDisplayed = ref<Boolean>(false)
    const visumToBeDeleted = ref<Visum>()
    const isDeletingVisum = ref<Boolean>(false)
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const { setBreadcrumbs, sidebar } = useNavigation()
    const { selectedGroup, selectedYear, years, setSelectedYear, getYearsForGroup, isInBetweenStartAndEnd } = useGroupAndYears()
    const { visums, isFetchingVisums, getVisums, navigateTowardsVisum } = useVisum()
    const { triggerNotification, isForbidden } = useNotification()
    const { can } = usePermission()

    setBreadcrumbs([])

    const editVisum = (visum: Visum) => {
      campSideBarState.value = {
        state: 'edit',
        entity: visum,
      }
    }

    const deleteCamp = () => {
      isDeletingVisum.value = true
      if (visumToBeDeleted.value) {
        RepositoryFactory.get(CampVisumRepository)
          .removeById(selectedGroup.value.groupAdminId, visumToBeDeleted.value.id)
          .then(() => {
              getVisums(selectedGroup.value, selectedYear.value).then(() => {
                isDeletingVisum.value = false
                isWarningDisplayed.value = false
                triggerNotification(t('pages.kampvisum-overview.notification-deleted'))
              })
          })
      }
    }

    const openCampSideBar = () => {
      campSideBarState.value = { state: 'new' }
    }

    const displayWarning = (visum: Visum) => {
      isWarningDisplayed.value = true
      visumToBeDeleted.value = visum
    }

    const hideWarning = () => {
      isWarningDisplayed.value = false
    }

    const actionSuccess = async (action: string) => {
      if (action === 'POST') {
        triggerNotification(t('pages.kampvisum-overview.notification-posted'))
      }
      if (action === 'UPDATE') {
        triggerNotification(t('pages.kampvisum-overview.notification-updated'))
      }
      // OPNIEUW FETCHEN
      if (!selectedYear.value) {
        await getYearsForGroup(selectedGroup.value.groupAdminId)
      }
      getVisums(selectedGroup.value, selectedYear.value)
    }

    const selectNewYear = (year: string) => {
      setSelectedYear(year)
    }

    const shineSelector = () => {
      // @ts-ignore
      document.getElementById('groupSelector').style.border = '2px solid #7b8f1c'
      sidebar.value.state = SidebarState.OPEN
    }

    return {
      navigateTowardsVisum,
      isWarningDisplayed,
      isInternetActive,
      isFetchingVisums,
      campSideBarState,
      visumToBeDeleted,
      openCampSideBar,
      isDeletingVisum,
      displayWarning,
      selectedGroup,
      actionSuccess,
      selectNewYear,
      shineSelector,
      SidebarState,
      hideWarning,
      deleteCamp,
      isForbidden,
      editVisum,
      sidebar,
      visums,
      years,
      t,
      selectedYear,
      isInBetweenStartAndEnd,
      can,
      permissions
    }
  },
})
