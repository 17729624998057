
import ParticipantSidebar from '../sideBars/ParticipantSideBar.vue'
import { useNotification } from '@/composable/useNotification'
import MemberSidebar from '../sideBars/MemberSidebar.vue'
import { CustomButtonSmall } from 'vue-3-component-library'
import { defineComponent, ref, PropType } from 'vue'
import Message, { ColorState } from './message.vue'
import { Check } from '@/serializer/Check'
import { Visum } from '@/serializer/Visum'
import MemberItem from './MemberItem.vue'
import { Member } from '@/serializer/Member'
import { useI18n } from 'vue-i18n'
import IInfo from '../icons/IInfo.vue'
import { usePermission } from '@/composable/usePermission'


export default defineComponent({
  name: 'MembersOverview',
  components: {
    Message,
    MemberItem,
    CustomButtonSmall,
    ParticipantSidebar,
    MemberSidebar,
    IInfo
  },
  props: {
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { triggerNotification } = useNotification()
    const { can } = usePermission()
    const sidebar = ref<any>({ state: 'hide' })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const openMemberSidebar = (): void => {
      sidebar.value = { state: 'search' }
    }

    const actionSuccess = (action: string) => {
      if (action === 'PATCH') {
        triggerNotification(t('checks.participant-check.notification-patched'))
      }
      if (action === 'DELETE') {
        triggerNotification(t('checks.participant-check.notification-deleted'))
      }
      emit('rl', true)
    }

    const openSidebarToEdit = (m: Member) => {
      sidebar.value = { state: 'edit', entity: m }
    }

    return {
      openSidebarToEdit,
      openMemberSidebar,
      actionSuccess,
      ColorState,
      sidebar,
      t,
      can
    }
  },
})
