import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex gap-2 items-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-lg mt-1"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.location.name)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.location.name), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.dateFromLocalisedString(_ctx.location.start_date)) + " - " + _toDisplayString(_ctx.dateFromLocalisedString(_ctx.location.end_date)), 1),
    (_ctx.location?.group?.full_name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.location.group.full_name), 1))
      : _createCommentVNode("", true),
    (_ctx.location?.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.location.name), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "mt-3 hover:underline text-green cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTowardsVisum({ id: _ctx.location.visum_id})))
    }, _toDisplayString(_ctx.$t('location-overview.check-camp')), 1)
  ]))
}