import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  id: "documents-container",
  class: "p-3 flex flex-col gap-5"
}
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_passport_nav_header = _resolveComponent("passport-nav-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_i_calendar = _resolveComponent("i-calendar")!
  const _component_i_marker = _resolveComponent("i-marker")!
  const _component_i_shield = _resolveComponent("i-shield")!
  const _component_i_euro = _resolveComponent("i-euro")!
  const _component_i_users = _resolveComponent("i-users")!
  const _component_i_puzzle = _resolveComponent("i-puzzle")!
  const _component_file_item = _resolveComponent("file-item")!
  const _component_passport_menu = _resolveComponent("passport-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_passport_nav_header, {
      visum: _ctx.visum,
      title: _ctx.t('passport.documents')
    }, null, 8, ["visum", "title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_loader, {
        color: "lightGreen",
        size: "20",
        isLoading: _ctx.isFetchingVisum
      }, null, 8, ["isLoading"])
    ]),
    (_ctx.visum)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visum.categorySet.categories, (category) => {
            return (_openBlock(), _createElementBlock("div", { key: category }, [
              _createVNode(_component_passport_menu, {
                title: category.categoryParent.label
              }, {
                "title-icon": _withCtx(() => [
                  (category.categoryParent.name === 'planning')
                    ? (_openBlock(), _createBlock(_component_i_calendar, { key: 0 }))
                    : _createCommentVNode("", true),
                  (category.categoryParent.name === 'logistics')
                    ? (_openBlock(), _createBlock(_component_i_marker, { key: 1 }))
                    : _createCommentVNode("", true),
                  (category.categoryParent.name === 'safety')
                    ? (_openBlock(), _createBlock(_component_i_shield, { key: 2 }))
                    : _createCommentVNode("", true),
                  (category.categoryParent.name === 'budget')
                    ? (_openBlock(), _createBlock(_component_i_euro, { key: 3 }))
                    : _createCommentVNode("", true),
                  (category.categoryParent.name === 'members_leaders')
                    ? (_openBlock(), _createBlock(_component_i_users, { key: 4 }))
                    : _createCommentVNode("", true),
                  (category.categoryParent.name === 'communication_agreements')
                    ? (_openBlock(), _createBlock(_component_i_puzzle, { key: 5 }))
                    : _createCommentVNode("", true)
                ]),
                data: _withCtx(() => [
                  (!_ctx.checkIfFilesAvailable(category.subCategories))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t('checks.document-check.no-uploaded-files')), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.subCategories, (subCategory) => {
                    return (_openBlock(), _createElementBlock("div", { key: subCategory }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subCategory.checks, (check) => {
                        return (_openBlock(), _createElementBlock("div", { key: check }, [
                          (check.checkParent.checkType.checkType === 'FileUploadCheck')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(check.value, (file) => {
                                  return (_openBlock(), _createElementBlock("div", { key: file }, [
                                    _createVNode(_component_file_item, {
                                      canBeDeleted: false,
                                      file: file,
                                      check: check,
                                      onActionSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionSuccess($event)))
                                    }, null, 8, ["file", "check"])
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["title"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}