
import IChevonDown from '../icons/IChevonDown.vue'
import IChevonUp from '../icons/IChevonUp.vue'
import useVisum from '@/composable/useVisum'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { IChevonUp, IChevonDown },
  name: 'CallToAction',
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const { visums } = useVisum()
    const isdisplayCallToAction = ref<boolean>(false)
    const  toggleIsdisplayCallToAction = () => {
      isdisplayCallToAction.value = !isdisplayCallToAction.value
    }

    return {
      toggleIsdisplayCallToAction,
      isdisplayCallToAction,
      visums,
      t,
    }
  },
})
