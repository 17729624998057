
import { BasePage, ScoutsDummyBar, defineRules } from 'vue-3-component-library'
import NavigationSideBar from './components/sideBars/NavigationSideBar.vue'
import Breadcrumb from './components/semantics/Breadcrumb.vue'
import Notification from './components/semantics/Notification.vue'
import { useInternetHelper } from './helpers/internetHelper'
import { defineComponent } from 'vue'
import router from '@/router'
import CustomFooter from './components/semantics/CustomFooter.vue'
import { usePhoneHelper } from './helpers/phoneHelper'
import { Loader } from 'vue-3-component-library'
import { isLoggedIn } from './helpers/authHelper'

export default defineComponent({
  name: 'App',
  components: {
    'base-page': BasePage,
    'scouts-dummy-bar': ScoutsDummyBar,
    'bread-crumb': Breadcrumb,
    NavigationSideBar,
    Notification,
    CustomFooter,
    Loader
  },
  setup() {
    const { checkIfInternetActive, isInternetActive } = useInternetHelper()
    checkIfInternetActive()

    defineRules()

    const { checkIfIsMobileSize } = usePhoneHelper()

    return {
      isInternetActive,
      router,
      checkIfIsMobileSize,
      isLoggedIn
    }
  },
})
