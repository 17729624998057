import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-3 bg-lighterGreen border-l-4 border-lightGreen" }
const _hoisted_2 = { class: "flex gap-4 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_i_checked = _resolveComponent("i-checked")!
  const _component_i_empty_check = _resolveComponent("i-empty-check")!
  const _component_i_check_warning = _resolveComponent("i-check-warning")!
  const _component_i_check_cross = _resolveComponent("i-check-cross")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.t('engagement.feedback')), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_custom_input, {
        onChangedTextArea: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changedTextArea($event))),
        textAreaWidth: "w-100 w-100",
        type: _ctx.InputTypes.TEXT_AREA,
        name: 'feedback'
      }, null, 8, ["type"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "flex gap-2 cursor-pointer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.select(_ctx.StatusFeedbackState.APPROVED)))
      }, [
        ((_ctx.selection === _ctx.StatusFeedbackState.APPROVED))
          ? (_openBlock(), _createBlock(_component_i_checked, { key: 0 }))
          : (_openBlock(), _createBlock(_component_i_empty_check, { key: 1 })),
        _createTextVNode(" " + _toDisplayString(_ctx.t('engagement.feedback-ok')), 1)
      ]),
      _createElementVNode("div", {
        class: "flex gap-2 cursor-pointer",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.select(_ctx.StatusFeedbackState.APPROVED_FEEDBACK)))
      }, [
        ((_ctx.selection === _ctx.StatusFeedbackState.APPROVED_FEEDBACK) || (_ctx.selection === _ctx.StatusFeedbackState.FEEDBACK_READ))
          ? (_openBlock(), _createBlock(_component_i_check_warning, { key: 0 }))
          : (_openBlock(), _createBlock(_component_i_empty_check, { key: 1 })),
        _createTextVNode(" " + _toDisplayString(_ctx.t('engagement.feedback-not-ok')), 1)
      ]),
      _createElementVNode("div", {
        class: "flex gap-2 cursor-pointer",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.select(_ctx.StatusFeedbackState.DISAPPROVED)))
      }, [
        ((_ctx.selection === _ctx.StatusFeedbackState.DISAPPROVED) || (_ctx.selection === _ctx.StatusFeedbackState.FEEDBACK_RESOLVED))
          ? (_openBlock(), _createBlock(_component_i_check_cross, { key: 0 }))
          : (_openBlock(), _createBlock(_component_i_empty_check, { key: 1 })),
        _createTextVNode(" " + _toDisplayString(_ctx.t('engagement.feedback-declined')), 1)
      ])
    ])
  ]))
}