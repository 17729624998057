import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font" }
const _hoisted_2 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_deadline_create_sidebar = _resolveComponent("deadline-create-sidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.t('pages.settings.deadlines.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_custom_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeadlineCreateSidebar())),
        class: "w-100",
        extraStyle: "w-100",
        isSubmitting: false,
        text: "+ nieuwe deadline maken"
      })
    ]),
    _createVNode(_component_deadline_create_sidebar, {
      title: "create",
      sideBarState: _ctx.createSidebar,
      "onUpdate:sideBarState": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.createSidebar) = $event)),
      onActionSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actionSuccess($event)))
    }, null, 8, ["sideBarState"])
  ], 64))
}