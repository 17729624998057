
import { EngagementRepository } from '@/repositories/EngagementRepository'
import { CustomButtonSmall } from 'vue-3-component-library'
import RepositoryFactory from '@/repositories/repositoryFactory'
import {CampVisumRepository} from '@/repositories/CampVisumRepository'
import IEmptyCheck from '@/components/icons/IEmptyCheck.vue'
import useGroupAndYears from '@/composable/useGroupAndYears'
import { defineComponent, PropType, toRefs, ref } from 'vue'
import { Visum, VisumStates } from '@/serializer/Visum'
import IChecked from '@/components/icons/IChecked.vue'
import { Engagement } from '@/serializer/Engagement'
import store from '../../store/store'
import DcNotes from './DcNotes.vue'
import { useI18n } from 'vue-i18n'
import Warning from '../semantics/Warning.vue'

export default defineComponent({
  name: 'Engagement',
  components: {
    CustomButtonSmall,
    IEmptyCheck,
    IChecked,
    Warning,
    DcNotes
  },
  props: {
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup(props: any, {emit}) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const user = store.getters.user
    const isWarningDisplayed = ref<boolean>(false)
    const isSigning = ref<boolean>(false)
    const { visum } = toRefs(props)
    const { selectedGroup } = useGroupAndYears()

    const sign = () => {
      isSigning.value = true
      if (props.visum.engagement) {
        RepositoryFactory.get(EngagementRepository).signVisum(selectedGroup.value.groupAdminId, props.visum.engagement).then(() => { 
          isSigning.value = false
          hideWarning()
          getEngagementState()
          rl()
        })
      }
    }

    const visumApprovalGlobal = () => {
      isSigning.value = true
      if (props.visum.engagement) {
        RepositoryFactory.get(CampVisumRepository).patchVisumApprovalGlobal(selectedGroup.value.groupAdminId, props.visum.id).then(() => { 
          isSigning.value = false
          hideWarning()
          getEngagementState()
          rl()
        })
      }
    }

    const handler = () => {
      if (props.visum.state !== VisumStates.NOT_SIGNABLE) {
        sign()

        if (props.visum.engagement.groupLeaders && selectedGroup.value.isDistrictCommissioner) {
          visumApprovalGlobal()
        }
      } else {
          VisumHandleFeedbackGlobal()
      }
    }

    const VisumHandleFeedbackGlobal = () => {
      isSigning.value = true
      if (props.visum.id) {
        RepositoryFactory.get(CampVisumRepository).patchVisumHandleFeedbackGlobal(selectedGroup.value.groupAdminId, props.visum.id).then(() => { 
          isSigning.value = false
          hideWarning()
          getEngagementState()
        })
      }
    }

    const getEngagementState = () => {
      RepositoryFactory.get(EngagementRepository)
      .getById(selectedGroup.value.groupAdminId, props.visum.engagement.id)
      .then((response: Engagement) => {
        visum.value.engagement = response
        rl()
      })
    }

    const hideWarning = () => {
      isWarningDisplayed.value = false
    }

    const displayWarning = () => {
      isWarningDisplayed.value = true
    }

    const warningText = (): string => {
      if (props.visum.state === VisumStates.NOT_SIGNABLE) {
        return t('engagement.warning-text-feedback-handled')
      }
      if (!props.visum.engagement.leaders) {
        return t('engagement.warning-text-leader')
      }
      if (props.visum.engagement.leaders && !props.visum.engagement.groupLeaders) {
        return t('engagement.warning-text-group-leader')
      }
      if (props.visum.engagement.leaders && props.visum.engagement.groupLeaders) {
        return t('engagement.warning-text-dc')
      }
      return ''
    }

    const warningButtonLeft = (): string => {
      if (props.visum.state === VisumStates.NOT_SIGNABLE) {
        return t('engagement.warning-left-button-feedback-handled')
      }
      if (!props.visum.engagement.leaders) {
        return t('engagement.warning-left-button-leader')
      }
      if (props.visum.engagement.leaders && !props.visum.engagement.groupLeaders) {
        return t('engagement.warning-left-button-group-leader')
      }
      if (props.visum.engagement.leaders && props.visum.engagement.groupLeaders) {
        return t('engagement.warning-left-button-dc')
      }
      return ''
    }

    const warningButtonRight = (): string => {
      if (props.visum.state === VisumStates.NOT_SIGNABLE) {
        return t('engagement.warning-right-button-feedback-handled')
      }
      if (!props.visum.engagement.leaders) {
        return t('engagement.warning-right-button-leader')
      }
      if (props.visum.engagement.leaders && !props.visum.engagement.groupLeaders) {
        return t('engagement.warning-right-button-group-leader')
      }
      if (props.visum.engagement.leaders && props.visum.engagement.groupLeaders) {
        return t('engagement.warning-right-button-dc')
      }
      return ''
    }

    const rl = () => {
      emit('rl', true)
    }

    return {
      isWarningDisplayed,
      warningButtonRight,
      warningButtonLeft,
      displayWarning,
      selectedGroup,
      hideWarning,
      VisumStates,
      warningText,
      isSigning,
      handler,
      visum,
      sign,
      user,
      rl,
      t,
    }
  },
})
