import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "w-100 grid grid-cols-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex gap-2 items-center font-bold" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle(_ctx.member))),
    class: _normalizeClass(_ctx.displayCheck ? 'cursor-pointer' : '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.member.fullName), 1)
        ]),
        (_ctx.member.birthDate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.dateFromLocalisedString(_ctx.member.birthDate)), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          disabled: !_ctx.displayCheck,
          class: _normalizeClass(_ctx.displayCheck ? 'cursor-pointer' : '' ),
          value: true,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.member.isChecked) = $event)),
          type: "checkbox",
          id: "checked",
          name: "checked"
        }, null, 10, _hoisted_5), [
          [_vModelCheckbox, _ctx.member.isChecked]
        ])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}