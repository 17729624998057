
import IRightArrow from '@/components/icons/IRightArrow.vue'
import IStar from '@/components/icons/IStar.vue'
import { defineComponent, PropType } from 'vue'
import { Visum } from '@/serializer/Visum'
import { useI18n } from 'vue-i18n'
import router from '@/router'

export default defineComponent({
  name: 'PassportOverviewItem',
  components:{
    IStar,
    IRightArrow
  },
  props: {
    title: String,
    navText: String,
    navUrl: String,
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup (props, { emit }) {

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const navigate = () => {
      router.push(`/kamp/${props.visum.id}/paspoort/${props.navUrl}`)
    }

    return {
      t,
      navigate
    }
  }
})
