import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "xs:w-100 md:w-80 mt-3"
}
const _hoisted_2 = {
  key: 1,
  class: "mt-3 p-1 bg-red text-white font-semibold",
  style: {"width":"max-content"}
}
const _hoisted_3 = {
  key: 2,
  class: "xs:w-100 md:w-80 mt-3"
}
const _hoisted_4 = { class: "flex flex-column" }
const _hoisted_5 = { class: "md:w-1/3 xs:w-80 bg-lighterGreen mt-3 p-3 shadow-md" }
const _hoisted_6 = { class: "text-xl mb-0 mt-0" }
const _hoisted_7 = { class: "mt-3 flex gap-2 flex-col" }
const _hoisted_8 = { class: "mt-3 md:w-1/3 xs:w-80" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button_small = _resolveComponent("custom-button-small")!
  const _component_i_empty_check = _resolveComponent("i-empty-check")!
  const _component_i_checked = _resolveComponent("i-checked")!
  const _component_dc_notes = _resolveComponent("dc-notes")!
  const _component_warning = _resolveComponent("warning")!

  return (_openBlock(), _createElementBlock("div", null, [
    (
    (_ctx.visum.state !== _ctx.VisumStates.APPROVED) && (_ctx.visum.state === _ctx.VisumStates.SIGNABLE || _ctx.visum.state === _ctx.VisumStates.DATA_REQUIRED || _ctx.visum.state === _ctx.VisumStates.FEEDBACK_HANDLED || _ctx.VisumStates.REVIEWED_FEEDBACK) &&
    (
      !_ctx.visum.engagement.leaders && _ctx.selectedGroup.isSectionLeader ||
      !_ctx.visum.engagement.groupLeaders && _ctx.selectedGroup.isGroupLeader ||
      _ctx.visum.engagement.groupLeaders && _ctx.selectedGroup.isDistrictCommissioner
    )
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_custom_button_small, {
            class: "w-100",
            extraStyle: 'w-100',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayWarning())),
            isSubmitting: false,
            text: _ctx.t('engagement.approve-camp')
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.visum.state === _ctx.VisumStates.NOT_SIGNABLE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t('engagement.feedback-handled-extra')), 1))
      : _createCommentVNode("", true),
    (_ctx.visum.state === _ctx.VisumStates.NOT_SIGNABLE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_custom_button_small, {
            class: "w-100",
            extraStyle: 'w-100',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayWarning())),
            isSubmitting: false,
            text: _ctx.t('engagement.feedback-handled')
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.t('engagement.title')), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex gap-2 items-center", !_ctx.visum.engagement.leaders ? 'opacity-50' : ''])
          }, [
            (!_ctx.visum.engagement.leaders)
              ? (_openBlock(), _createBlock(_component_i_empty_check, { key: 0 }))
              : (_openBlock(), _createBlock(_component_i_checked, { key: 1 })),
            _createElementVNode("strong", null, _toDisplayString(_ctx.t('engagement.leaders')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.visum.engagement.leaders?.firstName) + " " + _toDisplayString(_ctx.visum.engagement.leaders?.lastName), 1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["flex gap-2 items-center", !_ctx.visum.engagement.groupLeaders ? 'opacity-50' : ''])
          }, [
            (!_ctx.visum.engagement.groupLeaders)
              ? (_openBlock(), _createBlock(_component_i_empty_check, { key: 0 }))
              : (_openBlock(), _createBlock(_component_i_checked, { key: 1 })),
            _createElementVNode("strong", null, _toDisplayString(_ctx.t('engagement.group-leaders')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.visum.engagement.groupLeaders?.firstName) + " " + _toDisplayString(_ctx.visum.engagement.groupLeaders?.lastName), 1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["flex text-grey gap-2 items-center", !_ctx.visum.engagement.districtCommisioner ? 'opacity-50' : ''])
          }, [
            (!_ctx.visum.engagement.districtCommisioner)
              ? (_openBlock(), _createBlock(_component_i_empty_check, { key: 0 }))
              : (_openBlock(), _createBlock(_component_i_checked, { key: 1 })),
            _createElementVNode("strong", null, _toDisplayString(_ctx.t('engagement.dc')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.visum.engagement.districtCommisioner?.firstName) + " " + _toDisplayString(_ctx.visum.engagement.districtCommisioner?.lastName), 1)
          ], 2)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.selectedGroup.isDistrictCommissioner || _ctx.selectedGroup.isAdmin)
          ? (_openBlock(), _createBlock(_component_dc_notes, {
              key: 0,
              visum: _ctx.visum
            }, null, 8, ["visum"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_warning, {
      title: `${_ctx.user.firstName} ${_ctx.user.lastName}`,
      isLoading: _ctx.isSigning,
      isDisplayed: _ctx.isWarningDisplayed,
      text: _ctx.warningText(),
      leftButton: _ctx.warningButtonLeft(),
      rightButton: _ctx.warningButtonRight(),
      onLeftButtonClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handler())),
      onRightButtonClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hideWarning()))
    }, null, 8, ["title", "isLoading", "isDisplayed", "text", "leftButton", "rightButton"])
  ]))
}