
import { useSectionsHelper } from '@/helpers/sectionsHelper'
import { defineComponent, PropType } from 'vue'
import { Section } from '@/serializer/Section'
import IPencil from '../icons/IPencil.vue'
import Cross from '../icons/Cross.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SectionItem',
  components:{
    Cross, 
    IPencil,
  },
  emits: ['removeSection', 'editSection'],
  props: {
    groupSection: {
      type: Object as PropType<Section>,
      required: true
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })
    const { genderLabel } = useSectionsHelper()
    const remove = (section: Section) => {
      emit('removeSection', section)
    }
    const edit = (section: Section) => {
      emit('editSection', section)
    }
    return {
      genderLabel,
      remove,
      edit, 
      t
    }
  }
})
