import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-4 pt-4 pb-1" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-end px-4 pb-3"
}
const _hoisted_3 = {
  key: 1,
  class: "text-center py-5"
}
const _hoisted_4 = {
  key: 2,
  class: "mx-1 mb-72 overflow-y-auto"
}
const _hoisted_5 = { class: "mx-4" }
const _hoisted_6 = { class: "mt-5 py-4 px-4 absolute bottom-0 bg-white w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_participant_filter = _resolveComponent("participant-filter")!
  const _component_search_input = _resolveComponent("search-input")!
  const _component_loader = _resolveComponent("loader")!
  const _component_member_sidebar_item = _resolveComponent("member-sidebar-item")!
  const _component_custom_button_small = _resolveComponent("custom-button-small")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_side_bar, {
      width: "max-w-2xl",
      "is-display": _ctx.sideBarState.state !== 'hide',
      "is-edit": _ctx.sideBarState.state === 'edit',
      isOverflowHidden: true,
      onHideSidebar: _ctx.closeSideBar,
      selection: _ctx.selected,
      maxWidth: "max-w-2xl",
      name: "MemberSidebar",
      title: "Lid"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          class: "flex flex-col h-full",
          id: "memberForm",
          ref: "formDiv",
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_participant_filter, {
              ref: "filterChild",
              onChangedFilters: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changedFilters($event)))
            }, null, 512)
          ]),
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.filterChild.closeFilterMenu && _ctx.filterChild.closeFilterMenu(...args))),
            class: "p-4 mx-1"
          }, [
            _createVNode(_component_search_input, {
              filter: _ctx.filter,
              onChangedFilters: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changedFilters($event))),
              loading: _ctx.loading,
              "onUpdate:loading": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.loading) = $event)),
              name: "search",
              placeholder: _ctx.t('checks.participant-check.search'),
              repository: _ctx.ParticipantRepository,
              onFetchedOptions: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fetchedSearchResults($event)))
            }, null, 8, ["filter", "loading", "placeholder", "repository"])
          ]),
          (_ctx.fetchedMembers.length > 0 && _ctx.check.value.participantCheckType === 'M')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectAllFetchedMembers())),
                  class: "bg-green text-white px-2 py-1 cursor-pointer",
                  style: {"width":"fit-content"}
                }, _toDisplayString(_ctx.t('sidebars.member-sidebar.select-all')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_loader, {
                  color: "lightGreen",
                  size: "10",
                  isLoading: _ctx.loading
                }, null, 8, ["isLoading"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.filterChild && (!_ctx.filterChild.isFilterMenuOpen || _ctx.checkIfIsMobileSize() === false))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fetchedMembers, (member, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: member,
                      class: _normalizeClass([{ 'border-t-2 border-black': index === 0 }, "py-1 w-full shadow-md border-b-2 border-black bg-white p-2 inline-block text-left d-flex flex-col justify-content-between"])
                    }, [
                      _createVNode(_component_member_sidebar_item, {
                        displayCheck: _ctx.displayCheck(_ctx.check.checkParent.isMultiple, member, _ctx.fetchedMembers),
                        member: member
                      }, null, 8, ["displayCheck", "member"])
                    ], 2))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_custom_button_small, {
              disabled: !(_ctx.fetchedMembers.some((f) => f.isChecked === true)),
              isSubmitting: _ctx.isPatching,
              text: _ctx.t('checks.participant-check.add-without-plus')
            }, null, 8, ["disabled", "isSubmitting", "text"])
          ])
        ], 544)
      ]),
      _: 1
    }, 8, ["is-display", "is-edit", "onHideSidebar", "selection"])
  ]))
}