
import { InputTypes, CustomInput } from 'vue-3-component-library'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { useNotification } from '@/composable/useNotification'
import { CampVisumRepository } from '@/repositories/CampVisumRepository'
import { defineComponent, PropType, watch, ref } from 'vue'
import { Visum, VisumStates } from '@/serializer/Visum'
import { SubCategory } from '@/serializer/SubCategory'
import ICheckWarning from '../icons/ICheckWarning.vue'
import ICheckCross from '../icons/ICheckedCross.vue'
import IEmptyCheck from '../icons/IEmptyCheck.vue'
import IChecked from '../icons/IChecked.vue'
import { useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import useGroupAndYears from '@/composable/useGroupAndYears'

export enum StatusFeedbackState {
      FEEDBACK_RESOLVED = 'F',
      APPROVED_FEEDBACK ='N',
      FEEDBACK_READ = 'R',
      DISAPPROVED = 'D',
      UNDECIDED = 'U',
      APPROVED = 'A',
    }

export default defineComponent({
  name: 'Feedback',
  components: {
    CustomInput,
    IEmptyCheck,
    IChecked,
    ICheckWarning,
    ICheckCross
  },
  props: {
    subCategory: {
      type: Object as PropType<SubCategory>,
      required: true,
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup (props, {emit}) {
    const { selectedGroup } = useGroupAndYears()
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const { triggerNotification } = useNotification()
    let debounce: any

    const { values } = useForm({
      initialValues: { feedback: props.subCategory.feedback ? props.subCategory.feedback : '' },
    })

    const selection = ref<string>(props.subCategory.approval ? props.subCategory.approval : StatusFeedbackState.UNDECIDED)

    const select = (v: StatusFeedbackState) => {
      if (props.visum.state !== 'APPROVED') {
        selection.value = v
          if (props.subCategory.id) {
            RepositoryFactory.get(CampVisumRepository)
            .patchCategoryApproval(selectedGroup.value.groupAdminId, props.subCategory.id, selection.value)
            .then(() => {
              triggerNotification(t('engagement.feedback-notification'))
              rl()
            })
          }
      }
      
    }

    watch(
      () => values.feedback,
      () => {
        clearTimeout(debounce)
        debounce = setTimeout(() => {
          if (props.subCategory.id) {
          RepositoryFactory.get(CampVisumRepository)
          .patchCategoryFeedback(selectedGroup.value.groupAdminId, props.subCategory.id, values.feedback)
          .then(() => {
            triggerNotification(t('engagement.feedback-notification'))
            // rl()
          })
          }
        }, 1000)
      }
    )

    const rl = () => {
      emit('rl', true)
    }

    return {
      StatusFeedbackState,
      InputTypes,
      selection,
      VisumStates,
      values,
      select,
      t
    }
  }
})
