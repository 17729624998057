
import { defineComponent } from 'vue'
import { CustomInput, InputTypes } from 'vue-3-component-library'

export default defineComponent({
  name: 'DeadlineCreateCard',
  components: {
    CustomInput,
  },
  props: {
    index: String,
  },
  setup(props, context) {
    const removeItemFromArray = () => {
      context.emit('removeItemFromArray', props.index)
    }
    return {
      InputTypes,
      removeItemFromArray,
    }
  },
})
