import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = { class: "flex gap-1 items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "pb-3 mb-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "p-2.5 flex items-center gap-3" }
const _hoisted_7 = {
  key: 0,
  class: "p-2.5 items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!
  const _component_i_info = _resolveComponent("i-info")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_i_marker = _resolveComponent("i-marker")!
  const _component_parent_leaflet_map = _resolveComponent("parent-leaflet-map")!
  const _component_location_create_sidebar = _resolveComponent("location-create-sidebar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_message, {
        class: "p-2",
        title: _ctx.check.checkParent.label,
        color: { state: _ctx.ColorState.GRAY }
      }, null, 8, ["title", "color"]),
      (_ctx.check.checkParent.explanation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_i_info, {
              info: _ctx.check.checkParent.explanation
            }, null, 8, ["info"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_custom_button, {
        class: "mx-3 mb-3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLocationCreateSidebar())),
        text: _ctx.t('checks.location-check.add-location')
      }, null, 8, ["text"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.check.value.locations, (location) => {
        return (_openBlock(), _createElementBlock("div", { key: location }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(location.locations, (subLocation) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.centerInChildComponent(subLocation)),
              class: "flex justify-between md:border-b-2 hover:text-green cursor-pointer bg-white",
              key: subLocation
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_i_marker),
                _createTextVNode(" " + _toDisplayString(location.name) + " - " + _toDisplayString(subLocation.name ? `${subLocation.name}, ` : '') + _toDisplayString(`${subLocation.street} ${subLocation.houseNumber}, ${subLocation.postalcode} ${subLocation.township}, ${_ctx.getTranslationCountry(subLocation.country)}`), 1)
              ]),
              (location.startDate && location.endDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.dateFromLocalisedString(location.startDate)) + " - " + _toDisplayString(_ctx.dateFromLocalisedString(location.endDate)), 1))
                : _createCommentVNode("", true)
            ], 8, _hoisted_5))
          }), 128))
        ]))
      }), 128))
    ]),
    (!_ctx.isReloading)
      ? (_openBlock(), _createBlock(_component_parent_leaflet_map, {
          key: 0,
          ref: "child",
          parentLocations: _ctx.check.value.locations,
          check: _ctx.check,
          onRl: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rl($event))),
          onEdit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.edit($event)))
        }, null, 8, ["parentLocations", "check"]))
      : _createCommentVNode("", true),
    (_ctx.createSidebar.state === 'new' || _ctx.createSidebar.state === 'edit' || _ctx.createSidebar.state === 'search')
      ? (_openBlock(), _createBlock(_component_location_create_sidebar, {
          key: 1,
          parentLocations: _ctx.check.value.locations,
          check: _ctx.check,
          title: _ctx.createSidebar.state === 'new' ? _ctx.t('checks.location-check.create') : _ctx.createSidebar.state === 'edit' ? _ctx.t('checks.location-check.edit') : _ctx.createSidebar.state === 'search' ? _ctx.t('checks.location-check.search') : '' ,
          sideBarState: _ctx.createSidebar,
          "onUpdate:sideBarState": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.createSidebar) = $event)),
          onActionSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.actionSuccess($event)))
        }, null, 8, ["parentLocations", "check", "title", "sideBarState"]))
      : _createCommentVNode("", true)
  ]))
}