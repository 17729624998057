
  import { progressType, useUpload } from '../../composable/useUpload'
  import RepositoryFactory from '../../repositories/repositoryFactory'
  import { FileRepository } from '../../repositories/FileRepository'
  import { onMounted, ref, defineComponent, PropType } from 'vue'
  import MasterConfig from '@/models/config/masterConfig'
  import { FileItem } from '@/serializer/FileItem'
  import {Loader} from 'vue-3-component-library'
  import store from '@/store/store'
  import Dropzone from 'dropzone'
  const delay = require('delay');
  import { useI18n } from 'vue-i18n'
  import { useNotification } from '@/composable/useNotification'
  import useGroupAndYears from '@/composable/useGroupAndYears'
  
  export default defineComponent({
    name: 'Upload',
    components: {
      Loader
    },
    props: {
      progress: {
        type: [String, Number, Object] as PropType<progressType>,
        require: false,
        default: undefined,
      },
    },
    emits: ['update:progress', 'uploadedFile'],
    setup({}, { emit }) {
    const { triggerNotification } = useNotification()
      
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'local',
      })
      const fileCount = ref<number>(0)
      const uploading = ref<boolean>(false)
      const { uploadIsComplete } = useUpload()
      const doUpload = ref<() => void | undefined>()
      const config: MasterConfig = store.getters.config
      const dropzoneDiv = ref<HTMLDivElement | undefined>(undefined)
      const baseUrl = `${config.api.baseUrl}/${config.api.apiSuffix}`
      const dropzonePreviewDiv = ref<HTMLDivElement | undefined>(undefined)
      const listOfAllowed = '.jpg, .jpeg, .png, .webp, .odt, .ods, .odp, .docx, application/pdf, .pptx, .xlsx, .doc'
      const maxFileize = 20
      const { selectedGroup } = useGroupAndYears()
      onMounted(async () => {
        if (dropzoneDiv.value && dropzonePreviewDiv) {
          const myDropzone = new Dropzone(dropzoneDiv.value, {
            url: `${baseUrl}files/`,
            headers: {},
            autoProcessQueue: false,
            acceptedFiles: listOfAllowed,
            previewTemplate: dropzonePreviewDiv.value?.outerHTML,
            uploadMultiple: true,
            parallelUploads: 99,
            maxFiles: 99,
            maxFilesize: maxFileize
          })

          myDropzone.on("error", function(file: any, message: any) {
            myDropzone.files.forEach((f: any) => {
              if (f.status !== 'error') {
                // myDropzone.emit("addedfile", f);
                setTimeout(() => {
                  myDropzone.emit("addedfile", f);
                  myDropzone.files.push(f)
                },10)
              }
            });
            if (message.includes('File is too big')) {
              let part1 = t('checks.document-check.file-too-big-part-1')
              let part2 = message.substring(16, (message.indexOf('B') + 2))
              let part3 = t('checks.document-check.file-too-big-part-2')
              let sentence = `${part1} ${part2} ${part3}`
              triggerNotification(sentence)
            }
            if (message.includes("You can't upload files of this type.")) {
              triggerNotification(t('checks.document-check.file-type-incorrect'))
            }
          });

          myDropzone.on('totaluploadprogress', (progressDropzone: any) => {
            const progress: progressType = {
              status: 'inProgress',
              progress: Math.round(progressDropzone),
              successFiles: 0,
              errorFiles: 0,
            }

            uploading.value && emit('update:progress', progress)
          })

          myDropzone.on('addedfile', (value: any) => {
            // updateFileCount()
            fileCount.value++
          })

          myDropzone.on('removedfile', (value: any) => {
            // updateFileCount()
            fileCount.value--
          })

          myDropzone.on(
            'completemultiple',
            (files: { status: 'error' | 'success' }[]) => {
              uploading.value = false
              myDropzone.removeAllFiles()

              const progress: progressType = {
                status: 'success',
                progress: 0,
                successFiles: 0,
                errorFiles: 0,
              }

              files.forEach((file: { status: 'error' | 'success' }) => {
                if (file.status === 'error') {
                  progress.status = 'hasError'
                  progress.errorFiles = progress.errorFiles + 1
                }
                if (file.status === 'success') {
                  progress.status = 'success'
                  progress.successFiles = progress.successFiles + 1
                }
              })

              emit('update:progress', progress)
              uploadIsComplete()
            }
          )

          doUpload.value = () => {
            uploading.value = true
            myDropzone.files.forEach(async (f: any) => {
              await delay(100)
              uploadFile(f).finally(() => uploading.value = false)
            })

            myDropzone.removeAllFiles()
          }

          const uploadFile = async (file: any) => {
            if (file.status !== 'error') {
              await RepositoryFactory.get(FileRepository).uploadFile(selectedGroup.value.groupAdminId, file).then((responseFile: FileItem) => {
                try {
                  emit('uploadedFile', responseFile)
                } catch (error) {
                }
              })
            }
            
          }
        }
      })

      return {
        dropzonePreviewDiv,
        listOfAllowed,
        dropzoneDiv,
        uploading,
        fileCount,
        maxFileize,
        doUpload,
        t,
      }
    },
  })
