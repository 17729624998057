import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  key: 0,
  class: "w-100 mt-4"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { name: "sections" }
const _hoisted_6 = {
  class: "mt-5 py-4 sticky bottom-0 bg-white pl-3",
  style: {"margin-left":"-20px","margin-right":"-20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!
  const _component_custom_header = _resolveComponent("custom-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_base_side_bar = _resolveComponent("base-side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_side_bar, {
      isOverflowHidden: true,
      selection: _ctx.selected,
      "is-display": _ctx.sideBarState.state !== 'hide',
      "is-edit": _ctx.sideBarState.state === 'edit',
      name: "Camp",
      title: _ctx.title,
      onHideSidebar: _ctx.closeSideBar,
      width: "max-w-xl"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          id: "addNewCamp",
          ref: "formDiv",
          class: _normalizeClass([{ 'd-flex': _ctx.sideBarState.state === 'new' || _ctx.sideBarState.state === 'edit', 'd-none': _ctx.sideBarState.state === 'list' }, "flex-col relative overflow-y-scroll h-full px-4 pt-3"]),
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_custom_input, {
                disabled: _ctx.isSubmitting,
                type: _ctx.InputTypes.TEXT,
                rules: "required",
                name: "name",
                label: _ctx.t('sidebars.kampvisum-sidebar.input-fields.name')
              }, null, 8, ["disabled", "type", "label"])
            ]),
            (_ctx.sideBarState.state !== 'hide')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campTypes, (campType) => {
                    return (_openBlock(), _createElementBlock("div", { key: campType }, [
                      _createVNode(_component_custom_input, {
                        modelValue: _ctx.selectedCampTypes,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCampTypes) = $event)),
                        disabled: _ctx.isSubmitting,
                        type: _ctx.InputTypes.CHECK,
                        rules: "required",
                        name: campType.campType,
                        label: campType.label
                      }, null, 8, ["modelValue", "disabled", "type", "name", "label"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.sideBarState.state !== 'hide')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_custom_header, {
                    text: _ctx.t('sidebars.kampvisum-sidebar.sections-going'),
                    type: "h3"
                  }, null, 8, ["text"]),
                  _createVNode(_component_loader, {
                    color: "lightGreen",
                    size: "10",
                    isLoading: _ctx.isFetchingGroupSections
                  }, null, 8, ["isLoading"]),
                  _createElementVNode("span", _hoisted_5, [
                    _createVNode(_component_ErrorMessage, {
                      name: "sections",
                      class: "text-red text-sm block mt-1 w-80"
                    })
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupSections, (groupSection) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: groupSection.id
                    }, [
                      _createVNode(_component_custom_input, {
                        modelValue: _ctx.selectedGroupSections,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedGroupSections) = $event)),
                        disabled: _ctx.isSubmitting,
                        type: _ctx.InputTypes.CHECK,
                        rules: "required",
                        name: groupSection.id,
                        label: groupSection.name.name
                      }, null, 8, ["modelValue", "disabled", "type", "name", "label"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_custom_button, {
              isSubmitting: _ctx.isSubmitting,
              text: _ctx.sideBarState.state === 'edit' ? 'Bewerk' : _ctx.t('sidebars.kampvisum-sidebar.buttons.add')
            }, null, 8, ["isSubmitting", "text"])
          ])
        ], 34)
      ]),
      _: 1
    }, 8, ["selection", "is-display", "is-edit", "title", "onHideSidebar"])
  ]))
}