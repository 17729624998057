
import { SubCategory } from '@/serializer/SubCategory'
import { defineComponent, PropType } from 'vue'
import IInfo from '../icons/IInfo.vue'

export default defineComponent({
  name: 'HeaderSubcategoryCard',
  props: {
    subCategory: {
      type: Object as PropType<SubCategory>,
      required: true,
    },
  },
  components: {
    IInfo,
  },
})
