
import ILeftArrow from '@/components/icons/ILeftArrow.vue'
import { useNavigation } from '@/composable/useNavigation'
import { defineComponent, PropType } from 'vue'
import { Visum } from '@/serializer/Visum'

export default defineComponent({
  components: {ILeftArrow},
  name: 'PassportNavHeader',
  props: {
    title: String,
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  setup (props) {
    const { navigateTowardsPassport } = useNavigation()
    
    return {
      navigateTowardsPassport
    }
  }
})
