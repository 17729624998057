
import { CustomButtonSmall } from 'vue-3-component-library'
import { useSectionsHelper } from '../../helpers/sectionsHelper'
import { useNavigation } from '../../composable/useNavigation'
import { defineComponent, PropType } from 'vue'
import { VisumOverview } from '../../serializer/Visum'
import { useRoute } from 'vue-router'
import router from '@/router'
import useVisum from '@/composable/useVisum'
import CampGlobalStatusLabel from '@/components/semantics/CampGlobalStatusLabel.vue'
import { useI18n } from 'vue-i18n'
import { useInternetHelper } from '@/helpers/internetHelper'

export default defineComponent({
  name: 'CampInfoCard',
  components: {
    CampGlobalStatusLabel,
    'custom-button': CustomButtonSmall,
  },
  props: {
    visum: {
      type: Object as PropType<VisumOverview>,
      required: true,
    },
  },
  setup() {
    const { isInternetActive } = useInternetHelper()
    const route = useRoute()
    const { navigateTowardsCategory, navigateTowardsPassport } = useNavigation()
    const { getSectionsTitle } = useSectionsHelper()
    const { navigateTowardsVisum } = useVisum()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    return {
      navigateTowardsCategory,
      navigateTowardsPassport,
      navigateTowardsVisum,
      getSectionsTitle,
      route,
      isInternetActive,
      t
    }
  },
})
