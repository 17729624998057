import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center mb-3 px-3" }
const _hoisted_2 = { class: "flex items-center gap-3" }
const _hoisted_3 = { class: "font-semibold font-sans font-bold text-xl m-0" }
const _hoisted_4 = { class: "cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_info = _resolveComponent("i-info")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.subCategory.subCategoryParent.label), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_i_info, {
          info: _ctx.subCategory.subCategoryParent.explanation
        }, null, 8, ["info"])
      ])
    ])
  ]))
}