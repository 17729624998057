import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "font-bold",
  for: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_input = _resolveComponent("custom-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.check.checkParent.label), 1),
    _createVNode(_component_custom_input, {
      class: "w-52",
      type: _ctx.InputTypes.TEXT,
      name: 'number'
    }, null, 8, ["type"])
  ]))
}