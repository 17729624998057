
import { BaseSideBar, InputTypes, CustomInput, CustomButtonSmall, option } from 'vue-3-component-library'
import { ParticipantCheckRepository } from '@/repositories/ParticipantCheckRepository'
import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue'
import { ParticipantRepository } from '@/repositories/ParticipantRepository'
import MemberSidebarItem from '../semantics/MemberSidebarItem.vue'
import { useSelectionHelper } from '../../helpers/selectionHelper'
import ParticipantFilter from '../semantics/ParticipantFilter.vue'
import RepositoryFactory from '@/repositories/repositoryFactory'
import SearchInput from '../inputs/SearchInput.vue'
import { Filter } from '../../serializer/Filter'
import { Member } from '@/serializer/Member'
import { Check } from '@/serializer/Check'
import { Visum } from '@/serializer/Visum'
import { useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { usePhoneHelper } from '@/helpers/phoneHelper'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  name: 'ParticipantSideBar',
  components: {
    'base-side-bar': BaseSideBar,
    SearchInput,
    CustomInput,
    CustomButtonSmall,
    MemberSidebarItem,
    ParticipantFilter,
  },
  props: {
    sideBarState: {
      type: Object as PropType<any>,
      required: true,
      default: () => {
        'hide'
      },
    },
    check: {
      type: Object as PropType<Check>,
      required: true
    },
    visum: {
      type: Object as PropType<Visum>,
      required: true,
    },
  },
  emits: ['update:sideBarState', 'actionSuccess'],
  setup(props, context) {
    const filterChild2 = ref<any>(null)
    const selected = computed(() => (props.sideBarState.state === 'new' ? 'newParticipantSidebar' : 'searchParticipantSidebar'))
    const { resetForm, values, isSubmitting, handleSubmit } = useForm<Member>()
    const { displayCheck, checkForIdMatch } = useSelectionHelper()
    const fetchedMembers = ref<Member[]>([])
    const { sideBarState } = toRefs(props)
    const isPatching = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const filter = ref<Filter>({ gender: '', ageMin: '', ageMax: '', type: props.check.value.participantCheckType })
    const { checkIfIsMobileSize } = usePhoneHelper()
    const { selectedGroup } = useGroupAndYears()

    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const options = ref<option[]>([
      { text: 'Zoek', value: 'search' },
      { text: 'Nieuw', value: 'new' },
    ])

    const closeSideBar = () => {
      context.emit('update:sideBarState', { state: 'hide' })
      resetForm()
    }

    const onSubmit = async () => {
      // await validate().then((validation: any) => scrollToFirstError(validation, 'addNewCamp'))
      if (props.sideBarState.state === 'search') {
        patchMembers(fetchedMembers.value)
      }

      if (props.sideBarState.state === 'edit') {
          values.groupGroupAdminId = selectedGroup.value.groupAdminId
          await updateParticipant(values)
      }

      handleSubmit(async (values: Member) => {
        if (props.sideBarState.state === 'new') {
          values.groupGroupAdminId = props.visum.groupGroupAdminId
          await postParticipant(values)
        }
      })()
    }

    const updateParticipant = async (member: Member) => {
      if (member.id && props.sideBarState) {
        isPatching.value = true
        await RepositoryFactory.get(ParticipantRepository)
          .update(selectedGroup.value.groupAdminId, member.id, member)
          .then(() => {
            context.emit('actionSuccess', 'UPDATE')
            isPatching.value = false
            closeSideBar()
          })
      }
    }

    const patchMembers = async (members: Member[]) => {
      isPatching.value = true
      await RepositoryFactory.get(ParticipantCheckRepository)
        .update(selectedGroup.value.groupAdminId, props.check.endpoint, members)
        .then(() => {
          context.emit('actionSuccess', 'PATCH')
          isPatching.value = false
          closeSideBar()
        })
    }

    const postParticipant = async (participant: Member) => {
      participant.groupGroupAdminId = selectedGroup.value.groupAdminId
      await RepositoryFactory.get(ParticipantRepository)
        .create(selectedGroup.value.groupAdminId, participant)
        .then((m: Member) => {
          context.emit('actionSuccess', 'POST')
          m.isChecked = true
          patchMembers([m])
        })
    }

    const fetchedSearchResults = (results: Member[]) => {
      //ALSO CHECK ALREADY ADDED MEMBERS IN SEARCH RESULTS
      props.check.value.participants.forEach((alreadyAddedMember: Member) => {
        results.forEach((member: Member) => {
          if (member.id === alreadyAddedMember.id) {
            member.isChecked = true
          }
        })
      });
      
      loading.value = false
      //KEEP THE CHECKED MEMBERS
      let checkedMembers: Member[] = []

      fetchedMembers.value.forEach((fetchedMember: Member) => {
        if (fetchedMember.isChecked) {
          checkedMembers.push(fetchedMember)
        }
      })

      //SET CHECKED MEMBERS
      fetchedMembers.value = checkedMembers

      //ADD FETCHED RESULTS ONLY IF IT'S NOT ALREADY CHECKED
      results.forEach((r: Member) => {
        if (!(fetchedMembers.value.some((f: Member) => checkForIdMatch(f,r)))) {
          fetchedMembers.value.push(r)
        }
      })
    }

    const changeSideBar = (options: 'newParticipantSidebar' | 'searchParticipantSidebar') => {
      if (options === 'newParticipantSidebar') {
        context.emit('update:sideBarState', { state: 'new' })
      }

      if (options === 'searchParticipantSidebar') {
        context.emit('update:sideBarState', { state: 'search' })
      }
    }

    const changedFilters = (f: Filter) => {
      filter.value = f
      filter.value.type = props.check.value.participantCheckType
    }

    watch(() => props.sideBarState, () => {
      if (props.sideBarState.entity) {
      let m: Member = props.sideBarState.entity
      values.id = m.id
      values.firstName = m.firstName
      values.lastName = m.lastName
      values.email = m.email
      values.phoneNumber = m.phoneNumber
      values.city = m.city
      values.postalCode = m.postalCode
      values.street = m.street
      values.number = m.number
      values.letterBox = m.letterBox
      values.birthDate = m.birthDate
      values.comment = m.comment
      }
    })

    return {
      ParticipantRepository,
      fetchedSearchResults,
      fetchedMembers,
      changedFilters,
      changeSideBar,
      isSubmitting,
      sideBarState,
      closeSideBar,
      displayCheck,
      InputTypes,
      isPatching,
      selected,
      onSubmit,
      loading,
      options,
      values,
      filter,
      t,
      filterChild2,
      checkIfIsMobileSize
    }
  },
})
