
import { useNavigation } from '@/composable/useNavigation'
import { defineComponent } from 'vue'
import ILeftArrow from '../icons/ILeftArrow.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: { ILeftArrow },
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true,
      default: 'default title',
    },
    subTitle: String,
  },
  setup () {
    const route = useRoute()
    const { navigateTowardsVisum } = useNavigation()
    return {
      navigateTowardsVisum,
      route
    }
  }
})
