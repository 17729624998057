
import { FileCheckRepository } from '@/repositories/FileCheckRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { FileRepository } from '@/repositories/FileRepository'
import { FileItem } from '@/serializer/FileItem'
import { defineComponent, PropType } from 'vue'
import { Check } from '@/serializer/Check'
import { useI18n } from 'vue-i18n'
import { useInternetHelper } from '@/helpers/internetHelper'
import { useOfflineData } from '@/composable/useOfflineData'
import useGroupAndYears from '@/composable/useGroupAndYears'

export default defineComponent({
  name: 'FileItem',
  props: {
    canBeChecked: Boolean,
    file: {
      type: Object as PropType<FileItem>,
      required: true,
    },
    check: {
      type: Object as PropType<Check>,
      required: true,
    },
    canBeDeleted: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    })

    const { isInternetActive } = useInternetHelper()
    const { getFile } = useOfflineData()
    const { selectedGroup } = useGroupAndYears()

    const downloadFile = (id: string) => {
      if (isInternetActive.value) {
        RepositoryFactory.get(FileRepository)
        .getById(selectedGroup.value.groupAdminId, id)
        .then((minioData: { url: string}) => {
          window.open(minioData.url, '_blank');          
        })
      } else {
        getFile(id).then((file: any) => {
          const url = window.URL.createObjectURL(new Blob([file.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
        })
      }
    }

    const toggleCheck = (file: FileItem) => {
      file.isChecked = !file.isChecked
    }

    const deleteFromList = async (file: FileItem) => {
      if (props.check.id) {
        await RepositoryFactory.get(FileCheckRepository)
          .removeFileFromList(selectedGroup.value.groupAdminId, props.check.id, file.id)
          .then(() => {
            emit('actionSuccess', 'DELETE')
          })
      }
    }

    return {
      deleteFromList,
      downloadFile,
      toggleCheck,
      t
    }
  },
})
